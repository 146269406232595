import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';  // Assuming you have an AuthContext for handling auth

function EmailConfirmed() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const access = queryParams.get('access');
    const refresh = queryParams.get('refresh');

    if (access && refresh) {
      // Log the user in using the tokens
      login({ access, refresh });
      navigate('/');
      window.location.reload();  // Redirect to the home page or wherever you want
    } else {
      console.error('Tokens missing from URL');
    }
  }, [location, login, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <h1>Email Verified! Redirecting...</h1>
    </div>
  );
}

export default EmailConfirmed;
