import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { MessageSquare, Book, Settings } from 'lucide-react';
import NavigationBar from '../components/NavigationBar';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const ActionCard = ({ Icon, title, description, to, gradient }) => (
  <motion.div
    whileHover={{ scale: 1.03, boxShadow: "0 10px 20px rgba(0,0,0,0.2)" }}
    whileTap={{ scale: 0.98 }}
    className={`${gradient} p-6 rounded-3xl shadow-lg cursor-pointer transition-all duration-300`}
  >
    <Link to={to} className="flex flex-col items-center text-center">
      <Icon className="text-white mb-4" size={32} />
      <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
      <p className="text-gray-100 text-sm">{description}</p>
    </Link>
  </motion.div>
);

const HomePage = () => {
  const location = useLocation();
  const message = location.state?.message;

  return (
    <>
      <Helmet>
        <title>Funcon - Meaningful Conversations | Home</title>
        <meta name="description" content="Join Funcon for intimate discourse that propels us in self discovery. Explore diverse perspectives and foster personal growth through structured dialogue." />
      </Helmet>
      <div className="bg-[#1a1a1a] min-h-screen text-white font-inter">
        <NavigationBar />

        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h1 className="text-5xl mb-4">Welcome to Funcon 🫶</h1>
            <p className="text-xl text-gray-400">Meaningful conversations await you.</p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            <ActionCard 
              Icon={MessageSquare}
              title="Host a Funcon"
              description="Let us help with the hard stuff."
              to="/host"
              gradient="bg-gradient-to-br from-purple-600 to-indigo-600"
            />
            <ActionCard 
              Icon={Book}
              title="Funcon Cookbook"
              description="Insights into the structure of a Funcon."
              to="/cookbook"
              gradient="bg-gradient-to-br from-blue-600 to-cyan-600"
            />
            <ActionCard 
              Icon={Settings}
              title="Your Preferences"
              description="Tailor your Funcon rhythm and schedule."
              to="/preferences"
              gradient="bg-gradient-to-br from-green-600 to-teal-600"
            />
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
            className="text-center"
          >
            <h2 className="text-3xl font-semibold mb-4">Upcoming Funcons</h2>
            <p className="text-gray-400 mb-6">Stay tuned for exciting conversations!</p>
            <Link 
              to="/upcoming" 
              className="inline-block bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3 px-6 rounded-full hover:from-purple-700 hover:to-indigo-700 transition-colors"
            >
              View Schedule
            </Link>
          </motion.div>
        </main>

        <footer className="bg-[#2a2a2a] mt-20 py-8">
          <div className="max-w-7xl mx-auto px-4 text-center">
            <p className="text-gray-500">
              &copy; {new Date().getFullYear()} Funcon. Real Conversations.
              <a href="https://funcon.community/tos" className="ml-2 text-gray-400 hover:text-gray-300">Terms</a>
              <span className="mx-1">·</span>
              <a href="https://funcon.community/privacy" className="text-gray-400 hover:text-gray-300">Privacy</a>
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default HomePage;