import React, { createContext, useState, useEffect, useContext, useCallback, useRef } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext(null);

axios.defaults.baseURL = 'https://funcon-1f897c4af78f.herokuapp.com';

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => {
    const tokens = localStorage.getItem('authTokens');
    return tokens ? JSON.parse(tokens) : null;
  });
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const refreshTokenFunctionRef = useRef(null);

  const setAxiosAuthHeader = useCallback((token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, []);

  const fetchUserDetails = useCallback(async (userId) => {
    try {
      const response = await axios.get(`/api/user/${userId}/`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(() => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem('authTokens');
    setAxiosAuthHeader(null);
  }, [setAxiosAuthHeader]);

  const refreshToken = useCallback(async () => {
    if (!authTokens?.refresh) {
      setLoading(false);
      return false;
    }

    try {
      const response = await axios.post('/api/token/refresh/', {
        refresh: authTokens.refresh,
      });
      const newTokens = {
        ...authTokens,
        access: response.data.access,
      };
      setAuthTokens(newTokens);
      localStorage.setItem('authTokens', JSON.stringify(newTokens));
      setAxiosAuthHeader(newTokens.access);
      const decodedToken = jwtDecode(newTokens.access);
      await fetchUserDetails(decodedToken.user_id);
      return true;
    } catch (error) {
      console.error('Refresh token error:', error);
      logout();
      return false;
    }
  }, [authTokens, fetchUserDetails, setAxiosAuthHeader, logout]);

  refreshTokenFunctionRef.current = refreshToken;

  useEffect(() => {
    const initializeAuth = async () => {
      if (authTokens) {
        const decodedToken = jwtDecode(authTokens.access);
        if (decodedToken.exp * 1000 < Date.now()) {
          const refreshed = await refreshToken();
          if (!refreshed) {
            logout();
          }
        } else {
          setAxiosAuthHeader(authTokens.access);
          await fetchUserDetails(decodedToken.user_id);
        }
      } else {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [authTokens, fetchUserDetails, refreshToken, setAxiosAuthHeader, logout]);

  const login = async (email, password, googleTokens = null) => {
    try {
      if (googleTokens) {
        const { access, refresh } = googleTokens;
        const tokens = { access, refresh };
  
        setAuthTokens(tokens);
        localStorage.setItem('authTokens', JSON.stringify(tokens));
        setAxiosAuthHeader(tokens.access);
        
        const decodedToken = jwtDecode(tokens.access);
        await fetchUserDetails(decodedToken.user_id);
  
        return true;
      } else {
        const response = await axios.post('/api/token/', { email, password });
        setAuthTokens(response.data);
        localStorage.setItem('authTokens', JSON.stringify(response.data));
        setAxiosAuthHeader(response.data.access);
        const decodedToken = jwtDecode(response.data.access);
        await fetchUserDetails(decodedToken.user_id);
  
        return true;
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      return false;
    }
  };

  const signup = async (email, password) => {
    try {
      const response = await axios.post('/api/signup/', { email, password });
      if (response.data.message && response.data.message.includes('successfully')) {
        // User created successfully, now log them in
        const loginResponse = await axios.post('/api/token/', { email, password });
        if (loginResponse.data.access && loginResponse.data.refresh) {
          setAuthTokens(loginResponse.data);
          localStorage.setItem('authTokens', JSON.stringify(loginResponse.data));
          setAxiosAuthHeader(loginResponse.data.access);
          const decodedToken = jwtDecode(loginResponse.data.access);
          await fetchUserDetails(decodedToken.user_id);
          return { success: true, message: "User created and logged in successfully." };
        }
      }
      return { success: false, error: "Failed to create user." };
    } catch (error) {
      if (error.response && error.response.data) {
        return { success: false, error: error.response.data.error };
      } else {
        return { success: false, error: "An unexpected error occurred." };
      }
    }
  };

  const contextData = {
    user,
    authTokens,
    setAuthTokens,
    setUser,
    login,
    logout,
    refreshToken,
    loading,
    signup,
  };

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refreshed = await refreshTokenFunctionRef.current();
          if (refreshed) {
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, []);

  return (
    <AuthContext.Provider value={contextData}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);