import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { blogPosts } from '../content/blog-metadata';
import { ChevronDown, Home, X, Menu } from 'lucide-react';

const Cookbook = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['hosting', 'new-members', 'participating', 'funcon'];
      let current = '';

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element && window.scrollY >= element.offsetTop - 100) {
          current = section;
        }
      }

      setActiveSection(current);
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const sections = [
    {
      id: 'funcon',
      title: 'The Funcon',
      description: 'Understand the unique value of Funcon',
      topics: blogPosts.filter(post => post.category === 'The Funcon').map(post => ({
        title: post.title,
        slug: post.id
      }))
    },
    {
      id: 'hosting',
      title: 'Hosting',
      description: 'Master the art of hosting Funcons',
      topics: blogPosts.filter(post => post.category === 'Hosting').map(post => ({
        title: post.title,
        slug: post.id
      }))
    },
    {
      id: 'new-members',
      title: 'New Members',
      description: 'Everything new participants need to know',
      topics: blogPosts.filter(post => post.category === 'New Members').map(post => ({
        title: post.title,
        slug: post.id
      }))
    },
    {
      id: 'participating',
      title: 'Participating',
      description: 'Elevate your skills as a Funcon participant',
      topics: blogPosts.filter(post => post.category === 'Participating').map(post => ({
        title: post.title,
        slug: post.id
      }))
    },
  ];

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop - 80,
      });
    }
    setIsMenuOpen(false);
  };

  return (
    <div className="bg-[#1a1a1a] text-gray-100 min-h-screen font-sans">
      {/* Navigation */}
      <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-[#1a1a1a] shadow-md' : 'bg-transparent'}`}>
        <div className="max-w-5xl mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-2 text-purple-300 hover:text-purple-200 transition-colors">
            <Home size={20} />
            <span className="font-semibold">Home</span>
          </Link>
          <div className="hidden md:flex space-x-6">
            {sections.map((section) => (
              <span
                key={section.id}
                onClick={() => scrollTo(section.id)}
                className={`text-sm cursor-pointer ${
                  activeSection === section.id ? 'text-purple-400' : 'text-gray-300'
                } hover:text-purple-300 transition-colors`}
              >
                {section.title}
              </span>
            ))}
          </div>
          <button 
            className="md:hidden text-gray-300 hover:text-purple-300 transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-[#1a1a1a] z-40 pt-16"
          >
            <div className="flex flex-col items-center space-y-4 p-4">
              {sections.map((section) => (
                <span
                  key={section.id}
                  onClick={() => scrollTo(section.id)}
                  className={`text-lg cursor-pointer ${
                    activeSection === section.id ? 'text-purple-400' : 'text-gray-300'
                  } hover:text-purple-300 transition-colors`}
                >
                  {section.title}
                </span>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Hero Section */}
      <header className="h-screen flex items-center justify-center bg-gradient-to-br from-purple-900 to-[#1a1a1a]">
        <div className="text-center px-4">
          <motion.h1 
            className="text-5xl md:text-7xl font-bold mb-6 text-purple-300"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Funcon Cookbook
          </motion.h1>
          <motion.p 
            className="text-xl md:text-2xl text-gray-300 mb-12 max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            A guide to meaningful Funcon experiences
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <span 
              onClick={() => scrollTo('funcon')} 
              className="text-purple-300 hover:text-purple-200 transition-colors cursor-pointer inline-flex items-center"
            >
              Explore
              <ChevronDown className="ml-2" />
            </span>
          </motion.div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-5xl mx-auto px-4 py-16 space-y-32">
        {sections.map((section) => (
          <Section key={section.id} {...section} />
        ))}
      </main>

      {/* Footer */}
      <footer className="bg-[#242424] py-12 mt-32">
        <div className="max-w-5xl mx-auto px-4 text-center text-gray-400">
          <p>&copy; 2024 Funcon. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

const Section = ({ id, title, description, topics }) => {
  return (
    <motion.section 
      id={id}
      className="space-y-8"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true, margin: "-100px" }}
    >
      <h2 className="text-4xl font-bold text-purple-300">{title}</h2>
      <p className="text-xl text-gray-300 max-w-2xl">{description}</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {topics.map((topic, index) => (
          <Link to={`/blog/${topic.slug}`} key={index}>
            <motion.div 
              className="bg-[#242424] p-6 rounded-xl hover:shadow-lg transition-all border border-purple-800 cursor-pointer"
              whileHover={{ scale: 1.03, borderColor: '#9333ea' }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <h3 className="text-lg font-semibold mb-2 text-purple-200">{topic.title}</h3>
              <span className="text-purple-400 text-sm">Read more</span>
            </motion.div>
          </Link>
        ))}
      </div>
    </motion.section>
  );
};

export default Cookbook;