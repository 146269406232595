// src/content/blog-metadata.js

export const blogPosts = [
    {
      id: 'how-to-host',
      title: 'How to Host',
      date: '2024-09-10',
      author: 'Funcon Team',
      category: 'Hosting',
    },
    {
      id: 'what-is-an-observer',
      title: 'How to be an Observer',
      date: '2024-09-25',
      author: 'David Binswanger',
      category: 'Participating',
    },
    {
      id: 'from-good-to-great-hosting',
      title: 'From Good to Great: Hosting',
      date: '2024-09-11',
      author: 'Funcon Team',
      category: 'Hosting',
    },
    {
      id: 'your-first-invite',
      title: 'Your First Invite',
      date: '2024-09-12',
      author: 'Funcon Team',
      category: 'New Members',
    },
    {
      id: 'journey-of-the-funconner',
      title: 'The Journey of the Funconner',
      date: '2024-09-13',
      author: 'Funcon Team',
      category: 'New Members',
    },
    {
      id: 'being-great-participant',
      title: 'Being a Great Participant',
      date: '2024-09-14',
      author: 'Funcon Team',
      category: 'Participating',
    },
    {
      id: 'good-to-great-participating',
      title: 'Good to Great: Participating',
      date: '2024-09-15',
      author: 'Funcon Team',
      category: 'Participating',
    },
    {
      id: 'what-is-funcon',
      title: 'What is a Funcon?',
      date: '2024-09-16',
      author: 'Funcon Team',
      category: 'The Funcon',
    },
    {
      id: 'funcon-norms-practices',
      title: 'Funcon Norms & Best Practices',
      date: '2024-09-17',
      author: 'Funcon Team',
      category: 'The Funcon',
    },
  ];