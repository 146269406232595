import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';
// import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FunconLogo from '../assets/funcon-logo-horizontal.svg';
import { useAuth } from '../contexts/AuthContext';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'https://funcon-1f897c4af78f.herokuapp.com',
  withCredentials: true,
});

const Input = ({ label, ...props }) => (
  <div className="relative">
    <input
      {...props}
      className="w-full bg-transparent border-b border-gray-600 focus:border-purple-500 text-white p-4 outline-none transition-all duration-300 placeholder-transparent"
      placeholder={label}
    />
    <label className="absolute left-0 -top-3.5 text-gray-400 text-sm transition-all duration-300">
      {label}
    </label>
  </div>
);

const Button = ({ text, ...props }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="w-full bg-purple-600 text-white py-4 rounded-full font-semibold text-lg shadow-lg hover:bg-purple-700 transition duration-300"
    {...props}
  >
    {text}
  </motion.button>
);

function Signup() {
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signup } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const inviteFromUrl = params.get('invite');
    if (inviteFromUrl) {
      setInviteCode(inviteFromUrl);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (inviteCode !== 'func0nl1v325') {
      toast.error("Invalid invite code. Please try again.");
      return;
    }

    try {
      const response = await signup(email, password);
      if (response.success) {
        const redirectTo = searchParams.get('redirect_to')
        toast.success("Signed up and logged in successfully!");
        navigate(redirectTo ?? '/');
        window.location.reload(); // Redirect to home page or dashboard
      } else {
        handleSignupError(response.error);
      }
    } catch (error) {
      console.error('Sign up failed:', error);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  const handleSignupError = (error) => {
    switch (error) {
      case "Both email and password are required.":
        toast.error("Please provide both email and password.");
        break;
      case "Invalid email format:":
        toast.error("Please enter a valid email address.");
        break;
      case "Password must be at least 8 characters long.":
        toast.error("Your password must be at least 8 characters long.");
        break;
      case "A user with this email already exists.":
        toast.error("An account with this email already exists. Please use a different email or try logging in.");
        break;
      case "An error occurred while creating the user.":
        toast.error("We're having trouble creating your account. Please try again later.");
        break;
      default:
        toast.error(error || "Sign up failed. Please check your information and try again.");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#1a1a1a] text-white font-inter p-6">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <img src={FunconLogo} alt="Funcon Logo" className="mx-auto mb-12 w-40 h-auto" />
        <h1 className="text-4xl font-light text-center mb-12">Join Funcon</h1>
        <form onSubmit={handleSubmit} className="space-y-8">
          {error && <p className="text-red-500">{error}</p>}
          <Input 
            label="Email" 
            type="email"
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required
          />
          <Input 
            label="Password" 
            type="password"
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required
          />
          <Input 
            label="Invite Code" 
            type="text"
            value={inviteCode} 
            onChange={(e) => setInviteCode(e.target.value)} 
            required
          />
          <Button text="Sign Up" type="submit" />
        </form>
        <p className="text-center mt-12 text-gray-400">
          Already have an account?{' '}
          <Link to={searchParams.get('redirect_to') ? `/login?redirect_to=${searchParams.get('redirect_to')}`: '/login'} className="text-purple-400 hover:text-purple-300 transition duration-300">
            Log in
          </Link>
        </p>
      </motion.div>
      <ToastContainer position="bottom-center" theme="dark" />
    </div>
  );
}

export default Signup;