import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAxios from '../utils/axios';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';  // Ensure this path is correct

axios.defaults.withCredentials = true;

function EmailVerification() {
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const [countdown, setCountdown] = useState(3);
  const { key } = useParams();  // Key from the verification URL
  const navigate = useNavigate();
  const { login, refreshToken } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const verifyEmail = async () => {
      try {
        // Fetch the CSRF token
        const csrfResponse = await useAxios.get('/api/csrf/', { withCredentials: true });
        const csrfToken = csrfResponse.data.csrfToken;

        // Send request to verify email
        const response = await useAxios.post(
          '/api/auth/registration/account-confirm-email/',
          { key },
          {
            headers: {
              'X-CSRFToken': csrfToken
            },
            withCredentials: true
          }
        );

        // Check if access and refresh tokens are received
        if (response.data.access && response.data.refresh) {
          // Log the user in
          login(
            { access: response.data.access, refresh: response.data.refresh },
            { email: response.data.user.email, name: response.data.user.name }
          );

          // Update verification status and show success message
          setVerificationStatus('Email verified successfully!');
          toast.success('Your email has been verified. Redirecting to home page...');

          // Start the countdown for redirecting to homepage
          const timer = setInterval(() => {
            setCountdown((prevCount) => {
              if (prevCount <= 1) {
                clearInterval(timer);
                navigate('/');  // Redirect to the homepage
                return 0;
              }
              return prevCount - 1;
            });
          }, 1000);

          return () => clearInterval(timer);
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Email verification failed:', error);
        setVerificationStatus('Email verification failed. Please try again or contact support.');
        toast.error('Email verification failed. Please try again or contact support.');
      }
    };

    if (isMounted) verifyEmail();

    return () => {
      isMounted = false;  // Clean up on component unmount
    };
  }, [key, navigate, login, refreshToken]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-background text-textPrimary font-sans p-6">
      <div className="bg-surface p-10 rounded-lg shadow-lg w-full max-w-lg text-center">
        <h1 className="text-3xl font-bold mb-4">Email Verification</h1>
        <p>{verificationStatus}</p>
        {countdown > 0 && <p>Redirecting in {countdown}...</p>}
      </div>
    </div>
  );
}

export default EmailVerification;
