import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BlogPost from '../components/BlogPost';
import { blogPosts } from '../content/blog-metadata';

const BlogPostPage = () => {
  const { id } = useParams();
  const [postData, setPostData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setLoading(true);
        const metadata = blogPosts.find(post => post.id === id);
        if (!metadata) {
          throw new Error('Blog post not found');
        }

        const module = await import(`../content/blog-posts/${id}.js`);
        const content = module.default;

        setPostData({ ...metadata, content });
      } catch (err) {
        console.error('Failed to fetch blog post:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPostData();
  }, [id]);

  if (loading) {
    return <div className="text-white text-center mt-20">Loading...</div>;
  }

  if (error) {
    return <div className="text-white text-center mt-20">Error: {error}</div>;
  }

  if (!postData) {
    return <div className="text-white text-center mt-20">Blog post not found</div>;
  }

  return (
    <BlogPost
      title={postData.title}
      content={postData.content}
      date={postData.date}
      author={postData.author}
      category={postData.category}
    />
  );
};

export default BlogPostPage;