import React, { useState, useEffect } from 'react';
import useAxios from '../utils/axios';
import Button from '../components/Button';

function JoinFuncon() {
  const [funcons, setFuncons] = useState([]);
  const axios = useAxios();

  useEffect(() => {
    axios.get('/api/funcons/', { withCredentials: true })
      .then(response => setFuncons(response.data))
      .catch(error => console.error('Error fetching Funcons:', error));
  }, [axios]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Join a Funcon</h1>
      <ul className="mt-4">
        {funcons.map(funcon => (
          <li key={funcon.id} className="border-b p-2">
            <h2 className="text-xl">{funcon.title}</h2>
            <p>{funcon.description}</p>
            <Button text="View Details" onClick={() => window.location.href = `/funcon/${funcon.id}`} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default JoinFuncon;
