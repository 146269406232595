import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#1a1a1a] mt-20 py-8">
      <div className="max-w-6xl mx-auto px-4 text-center">
        <p className="text-gray-300">
          &copy; 2024 Funcon. Meaningful interactions in a technology-dependent world.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
