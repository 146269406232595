import React, { useState } from 'react';
import useAxios from '../utils/axios';
import Input from '../components/Input';
import TextArea from '../components/Textarea';
import Button from '../components/Button';

// YARD: remove this on frontend
function Feedback() {
  const [wouldAttendAgain, setWouldAttendAgain] = useState('');
  const [comments, setComments] = useState('');
  const axios = useAxios();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/api/feedback/', { would_attend_again_with_host: wouldAttendAgain === 'yes', comments, withCredentials: true })
      .then(response => {
        console.log('Feedback submitted:', response.data);
        window.location.href = '/';
      })
      .catch(error => console.error('Error submitting feedback:', error));
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Funcon Feedback</h1>
      <form onSubmit={handleSubmit} className="mt-4">
        <label className="block mb-2">
          Would you attend another Funcon with this host?
          <select
            value={wouldAttendAgain}
            onChange={e => setWouldAttendAgain(e.target.value)}
            className="block w-full mt-1 p-2 border"
          >
            <option value="">Select an option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>
        <TextArea label="Comments" value={comments} onChange={e => setComments(e.target.value)} />
        <Button text="Submit Feedback" />
      </form>
    </div>
  );
}

export default Feedback;
