import React, { useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import useAxios from "../utils/axios";
import { AuthContext } from "../contexts/AuthContext";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as AccountIcon } from "../assets/icons/account-circle.svg";
import { ReactComponent as HandClock } from "../assets/icons/hand-clock.svg";
import { ReactComponent as FunconIcon } from "../assets/icons/funcon.svg";
import AddToCalendarDropdown from "../components/AddToCalendarDropdown";
import { Calendar, ArrowLeft, Users, Clock, Video } from "lucide-react";
import { ReactComponent as CreateFunconIcon } from "../assets/icons/create-funcon.svg";

const ViewFuncon = () => {
  const [funcon, setFuncon] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id: funconId } = useParams();
  const { user } = useContext(AuthContext);
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const fetchFuncon = async () => {
    try {
      const result = await axiosInstance.get(`/api/user/${user.id}/funcons/${funconId}`);
      setFuncon(result.data);
    } catch (err) {
      if (err.status === 401) {
        toast.error("Unauthorized to view this funcon");
        navigate("/upcoming");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFuncon();
  }, [funconId]);

  const onAccept = async () => {
    try {
      await axiosInstance.put(`/api/user/${user.id}/funcons/${funcon.id}/response`, {
        accept: true,
      });
      await fetchFuncon();
      toast.success("Invite accepted successfully");
    } catch (error) {
      toast.error("Failed to accept invite");
    }
  };

  const onDecline = async () => {
    try {
      await axiosInstance.put(`/api/user/${user.id}/funcons/${funcon.id}/response`, {
        accept: false,
      });
      await fetchFuncon();
      toast.success("Invite declined successfully");
    } catch (error) {
      toast.error("Failed to decline invite");
    }
  };

  if (loading) {
    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="min-h-screen bg-[#1a1a1a] flex flex-col"
      >
        <NavigationBar />
        <div className="flex-grow flex justify-center items-center">
          <div className="flex flex-col items-center gap-4">
            <div className="relative w-16 h-16">
              <motion.div
                className="absolute inset-0 rounded-full border-2 border-[#B39DDB]/20 border-t-[#B39DDB]"
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              />
              <motion.div
                className="absolute inset-2 rounded-full border-2 border-[#B39DDB]/10 border-t-[#B39DDB]/60"
                animate={{ rotate: -360 }}
                transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
              />
              <motion.div
                className="absolute inset-4 rounded-full border-2 border-[#B39DDB]/5 border-t-[#B39DDB]/40"
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              />
            </div>
            <motion.p 
              className="text-gray-400 text-lg"
              animate={{ opacity: [0.5, 1, 0.5] }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              Loading Funcon details...
            </motion.p>
          </div>
        </div>
        <Footer />
      </motion.div>
    );
  }

  const date = new Date(funcon.scheduled_datetime);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZone: "America/New_York",
    timeZoneName: "short"
  }).format(date).replace(',', ' at');

  const isHost = funcon.host.id === user.id;

  const isPendingResponse = !funcon?.accepted && !funcon?.declined;

  return (
    <div className="bg-[#1a1a1a] min-h-screen text-white font-inter flex flex-col">
      <NavigationBar />
      <main className="flex-grow flex flex-col items-center px-3 py-4 sm:p-6 md:p-8">
        <div className="w-full max-w-4xl">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Link 
              to="/upcoming"
              className="group inline-flex items-center text-gray-400 hover:text-[#B39DDB] transition-colors mb-4 sm:mb-8"
            >
              <ArrowLeft className="w-5 h-5 sm:w-4 sm:h-4 mr-2 group-hover:-translate-x-1 transition-transform" />
              <span className="text-base sm:text-sm font-medium">Back to Upcoming</span>
            </Link>
          </motion.div>
          
          <motion.div 
            className="w-full mb-28 sm:mb-0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="relative bg-[#333333] rounded-[8px] p-5 sm:p-8 md:p-10 shadow-xl border border-[#b39ddc] overflow-hidden">
              <div className="relative space-y-6 sm:space-y-8">
                <div className="flex flex-col gap-6 sm:gap-8">
                  <motion.div 
                    className="flex items-start sm:items-center gap-3 sm:gap-4"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <div className={`h-12 w-12 sm:h-14 sm:w-14 rounded-lg flex-shrink-0 ${
                      isHost ? 'bg-[#b39ddc]/20 ring-1 ring-[#b39ddc]/30' : 'bg-[#b39ddc]/10'
                    } flex items-center justify-center transition-all duration-300`}>
                      <Users className={`w-6 h-6 sm:w-7 sm:h-7 ${isHost ? 'text-[#b39ddc]' : 'text-[#b39ddc]/80'}`} />
                    </div>
                    <div className="flex-grow">
                      <h2 className="text-sm font-medium text-[#b0b0b0] mb-1">
                        {isHost ? "You're hosting" : "Funcon with"}
                      </h2>
                      <p className="text-lg sm:text-xl font-medium text-white">
                        {isHost ? "this Funcon" : `${funcon?.host?.first_name} ${funcon?.host?.last_name}`}
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="text-sm font-medium text-gray-400 mb-1.5">Your Invite:</p>
                      <span className={`
                        inline-flex items-center px-3 py-1.5 rounded-[6px] text-sm font-medium 
                        whitespace-nowrap
                        ${funcon?.accepted ? 'bg-green-500/10 text-green-400 ring-1 ring-green-500/30' : 
                          funcon?.declined ? 'bg-red-500/10 text-red-400 ring-1 ring-red-500/30' : 
                          'bg-yellow-500/10 text-yellow-400 ring-1 ring-yellow-500/30'}
                      `}>
                        <span className={`w-1.5 h-1.5 rounded-full mr-2 ${
                          funcon?.accepted ? 'bg-green-400' :
                          funcon?.declined ? 'bg-red-400' :
                          'bg-yellow-400'
                        }`} />
                        {funcon?.accepted ? 'Accepted' : funcon?.declined ? 'Declined' : 'Pending'}
                      </span>
                    </div>
                  </motion.div>

                  {isPendingResponse && (
                    <div className="flex flex-col gap-4 p-6 bg-yellow-500/5 border border-yellow-500/20 rounded-lg">
                      <div className="flex items-center gap-2.5">
                        <HandClock className="w-5 h-5 text-yellow-400" />
                        <span className="text-sm text-yellow-400">
                          Pending Response
                        </span>
                      </div>
                      
                      <div className="flex gap-4">
                        <button
                          onClick={onAccept}
                          className="flex-1 flex items-center justify-center gap-2.5 px-4 py-3 bg-green-500/10 hover:bg-green-500/20 border border-green-500/20 rounded-lg text-green-400 transition-colors"
                        >
                          <Check className="w-5 h-5" />
                          <span className="text-sm font-medium">Accept Invite</span>
                        </button>

                        <button
                          onClick={onDecline}
                          className="flex-1 flex items-center justify-center gap-2.5 px-4 py-3 bg-red-500/10 hover:bg-red-500/20 border border-red-500/20 rounded-lg text-red-400 transition-colors"
                        >
                          <Close className="w-5 h-5" />
                          <span className="text-sm font-medium">Decline Invite</span>
                        </button>
                      </div>
                    </div>
                  )}

                  <motion.div 
                    className="space-y-4"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                  >
                    <div className="relative">
                      <h1 
                        className={`text-xl sm:text-2xl md:text-3xl font-medium text-white leading-normal sm:leading-relaxed
                          ${showFullDescription ? '' : 'line-clamp-7'}`}
                      >
                        {funcon?.topic?.description}
                      </h1>
                      
                      {funcon?.topic?.description?.split('\n').length > 7 && (
                        <motion.button
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          onClick={() => setShowFullDescription(!showFullDescription)}
                          className="mt-2 text-[#b39ddb] hover:text-[#9B7FC3] text-sm font-medium transition-colors"
                        >
                          {showFullDescription ? 'See less' : 'See more'}
                        </motion.button>
                      )}
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                      <div className="flex items-center gap-3 bg-[#333333] rounded-[6px] px-4 py-3.5 border border-[#b39ddc]/30">
                        <HandClock className="w-5 h-5 text-[#B39DDB] flex-shrink-0" />
                        <span className="text-[#b0b0b0]">{formattedDate}</span>
                      </div>
                      <div className="flex items-center gap-3 bg-[#333333] rounded-[6px] px-4 py-3.5 border border-[#b39ddc]/30">
                        <FunconIcon className="w-5 h-5 text-[#B39DDB] flex-shrink-0" />
                        <div className="flex flex-col">
                          <span className="text-white font-medium">
                            {funcon?.participants?.length} Attendees
                          </span>
                          <span className="text-sm text-[#b0b0b0]">
                            {funcon?.accepted_count || 0} accepted
                          </span>
                        </div>
                      </div>
                    </div>
                  </motion.div>

                  <AnimatePresence mode="wait">
                    {funcon?.accepted && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ delay: 0.5 }}
                        className="sm:static sm:mt-6"
                      >
                        <AddToCalendarDropdown funcon={funcon}>
                          <motion.button 
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="w-full bg-[#b39ddc] text-white rounded-[6px] py-4 px-6 font-medium 
                              transition-all duration-300 flex items-center justify-center gap-3 
                              hover:bg-[#9B7FC3]"
                          >
                            <Calendar className="w-5 h-5" />
                            Add to Calendar
                          </motion.button>
                        </AddToCalendarDropdown>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </main>

      <Link
        to="/host"
        className={`
          fixed bottom-4 right-4 
          sm:bottom-8 sm:right-8 
          md:right-12 md:bottom-12
          z-50 flex items-center gap-2 
          px-4 sm:px-6 py-3 
          bg-[#B39DDB] rounded-[8px] text-white 
          shadow-lg hover:bg-[#9B7FC3] 
          transition-all duration-200 
          group
        `}
      >
        <CreateFunconIcon className="w-5 h-5 sm:w-6 sm:h-6 group-hover:rotate-90 transition-transform duration-200" />
        <span className="text-sm sm:text-base font-inter font-medium">Create Funcon</span>
      </Link>

      <ToastContainer 
        position="bottom-center" 
        theme="dark"
        toastClassName="bg-gray-800/90 backdrop-blur border border-gray-700 mx-4 mb-20 sm:mx-0 sm:mb-0"
      />
      <Footer />
    </div>
  );
};

export default ViewFuncon;
