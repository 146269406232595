import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';
import FunconLogo from '../assets/funcon-logo-horizontal.svg';
import Button from '../components/Button';
import Input from '../components/Input';
import axios from 'axios';

function ForgotPassword() {
  const [email, setEmail] = useState(''); // State to hold email input

  const Button = ({ text, ...props }) => (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-full bg-purple-600 text-white py-4 rounded-full font-semibold text-lg shadow-lg hover:bg-purple-700 transition duration-300"
      {...props}
    >
      {text}
    </motion.button>
  );
  

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/forgot-password/', { email });

      // Success message if backend returns 200
      if (response.status === 200) {
        toast.success('Password reset link has been sent to your email.');
      } else {
        toast.error('Email not found. Please check and try again.');
      }
    } catch (error) {
      console.error('Forgot Password Error:', error);

      // Error handling for 404 status
      if (error.response && error.response.status === 404) {
        toast.error('User with this email does not exist.');
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#1a1a1a] text-white font-inter p-6">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <img src={FunconLogo} alt="Funcon Logo" className="mx-auto mb-12 w-40 h-auto" />
        <h1 className="text-4xl font-light text-center mb-12">Forgot Password</h1>

        {/* Form to collect and submit email */}
        <form onSubmit={handleSubmit} className="space-y-8">
          <Input
            label="Email"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button text="Send Reset Link" type="submit" />
        </form>
      </motion.div>

      {/* Toast notifications container */}
      <ToastContainer position="bottom-center" theme="dark" />
    </div>
  );
}

export default ForgotPassword;
