import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import useAxios from '../utils/axios';
import { toast } from 'react-toastify';
import NavigationBar from '../components/NavigationBar';
import Select from 'react-select';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const RadioGroup = ({ label, options, value, onChange }) => (
  <div className="mb-6 sm:mb-8">
    <label className="text-base sm:text-lg mb-2 sm:mb-4 block">{label}</label>
    <div className="flex flex-col space-y-3 sm:space-y-4">
      {options.map((option) => (
        <label key={option.value} className="flex items-center cursor-pointer group">
          <input
            type="radio"
            value={option.value}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
            className="sr-only"
          />
          <div className="w-4 h-4 sm:w-5 sm:h-5 border-2 border-gray-400 rounded-full mr-2 flex items-center justify-center group-hover:border-purple-400 transition-colors">
            {value === option.value && (
              <div className="w-2 h-2 sm:w-3 sm:h-3 bg-purple-600 rounded-full"></div>
            )}
          </div>
          <span className="text-sm sm:text-base text-gray-300 group-hover:text-white transition-colors">{option.label}</span>
        </label>
      ))}
    </div>
  </div>
);

const CustomSlider = ({ value, onChange, min, max }) => {
  const handleClick = (newValue) => {
    onChange({ target: { value: newValue } });
  };

  return (
    <>
    <Helmet>
      <title>Funcon - Discover Meaningful Conversations | Join Our Community</title>
      <meta name="description" content="Join Funcon and engage in intimate, thought-provoking conversations. Explore diverse perspectives, foster personal growth, and build meaningful connections." />
    </Helmet>
    <div className="w-full py-4 sm:py-6">
      <div className="flex justify-between items-center">
        {[...Array(max - min + 1)].map((_, index) => {
          const currentValue = index + min;
          const isSelected = currentValue <= value;
          return (
            <div 
              key={currentValue}
              className="flex flex-col items-center cursor-pointer"
              onClick={() => handleClick(currentValue)}
            >
              <div 
                className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full flex items-center justify-center mb-1 sm:mb-2 transition-all duration-200 ${
                  isSelected ? 'bg-purple-600 text-white' : 'bg-gray-700 text-gray-400'
                }`}
              >
                <span className="text-xs sm:text-sm">{currentValue}</span>
              </div>
              <div 
                className={`w-1 h-1 sm:w-2 sm:h-2 rounded-full transition-all duration-200 ${
                  isSelected ? 'bg-purple-600' : 'bg-gray-700'
                }`}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="relative mt-2">
        <div className="absolute left-0 right-0 h-1 bg-gray-700 rounded-full"></div>
        <div 
          className="absolute left-0 h-1 bg-purple-600 rounded-full transition-all duration-200"
          style={{ width: `${((value - min) / (max - min)) * 100}%` }}
        ></div>
      </div>
    </div>
  </>
  );
};

function PreferencesPage() {
  const [preferences, setPreferences] = useState({
    time_preference: 'day',
    day_preference: 'weekday',
    funcons_per_month: 3,
    timezone: 'America/New_York',
  });
  const [loading, setLoading] = useState(true);
  const [timezones] = useState(() => {
    const allTimezones = moment.tz.names().map(tz => {
      const now = moment().tz(tz);
      const abbreviation = now.zoneAbbr();
      const offset = now.format('Z');
      const isDST = now.isDST();
      const continent = tz.split('/')[0];
      return {
        value: tz,
        label: `(${abbreviation}) ${tz.replace(/_/g, ' ')} (UTC${offset})${isDST ? ' DST' : ''}`,
        continent: continent
      };
    });

    const groupedTimezones = allTimezones.reduce((acc, tz) => {
      if (!acc[tz.continent]) {
        acc[tz.continent] = [];
      }
      acc[tz.continent].push(tz);
      return acc;
    }, {});

    return Object.entries(groupedTimezones).map(([continent, zones]) => ({
      label: continent,
      options: zones
    }));
  });

  const axiosInstance = useAxios();

  useEffect(() => {
    fetchPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
      const response = await axiosInstance.get('/api/preferences/', {withCredentials: true});
      console.log('API Response:', response.data);  // Add this line
      if (response.data && response.data.time_preference) {
        // If the response contains preference data, use it
        setPreferences(response.data);
      } else {
        // If the response doesn't contain preference data, use default values
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setPreferences({
          time_preference: 'day',
          day_preference: 'weekday',
          funcons_per_month: 3,
          timezone: userTimeZone || 'America/New_York'
        });
      }
    } catch (error) {
      console.error('Error fetching preferences:', error);
      toast.error('Unable to load preferences', { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };

  const updatePreference = async (key, value) => {
    try {
      const newPreferences = { ...preferences, [key]: value };
      setPreferences(newPreferences);
      await axiosInstance.post('/api/preferences/', newPreferences, {withCredentials: true});
      toast.success('Preference updated', { autoClose: 1000, hideProgressBar: true });
    } catch (error) {
      console.error('Error saving preference:', error);
      toast.error('Unable to save preference', { autoClose: 2000 });
    }
  };

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  return (
    <>
    <Helmet>
      <title>Funcon - Discover Meaningful Conversations | Join Our Community</title>
      <meta name="description" content="Join Funcon and engage in intimate, thought-provoking conversations. Explore diverse perspectives, foster personal growth, and build meaningful connections." />
    </Helmet>
    <div className="bg-[#1A1A1A] min-h-screen text-white">
      <NavigationBar />
      <div className="max-w-xl mx-auto px-4 sm:px-6 py-8 sm:py-16">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-2xl sm:text-4xl font-light mb-6 sm:mb-12 text-center"
        >
          What Works Best For You?
        </motion.h1>
        <div className="bg-[#2A2A2A] rounded-xl sm:rounded-2xl p-6 sm:p-10 shadow-xl relative">
          <div className="mb-4 sm:mb-6 sm:absolute sm:top-3 sm:right-3 text-xs text-gray-500">
            <Select
              options={timezones}
              value={timezones.flatMap(group => group.options).find(tz => tz.value === preferences.timezone)}
              onChange={(option) => updatePreference('timezone', option.value)}
              className="w-full sm:w-72"
              classNamePrefix="select"
              placeholder="Search for a timezone..."
              isSearchable={true}
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: '#2A2A2A',
                  border: 'none',
                  boxShadow: 'none',
                  minHeight: '20px',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: '#FFFFFF',
                }),
                input: (provided) => ({
                  ...provided,
                  color: '#FFFFFF',
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: '#2A2A2A',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#3A3A3A' : '#2A2A2A',
                  color: '#FFFFFF',
                }),
                group: (provided) => ({
                  ...provided,
                  padding: 0,
                }),
                groupHeading: (provided) => ({
                  ...provided,
                  backgroundColor: '#1A1A1A',
                  color: '#9CA3AF',
                  fontWeight: 'bold',
                  fontSize: '0.8rem',
                  padding: '8px 12px',
                }),
              }}
            />
          </div>
          <RadioGroup 
            label="Night or Day Funcons?"
            options={[
              { value: 'day', label: '🌤️ Day' },
              { value: 'night', label: '🌙 Night' }
            ]}
            value={preferences.time_preference}
            onChange={(value) => updatePreference('time_preference', value)}
          />
          <RadioGroup 
            label="Weekend or Weekday Funcons?"
            options={[
              { value: 'weekday', label: '☕ Weekdays' },
              { value: 'weekend', label: '🏖️ Weekends' }
            ]}
            value={preferences.day_preference}
            onChange={(value) => updatePreference('day_preference', value)}
          />
          <div className="mb-6 sm:mb-8">
            <label className="text-base sm:text-lg mb-2 sm:mb-4 block">How many Funcons per month?</label>
            <CustomSlider 
              min={1}
              max={10}
              value={preferences.funcons_per_month}
              onChange={(e) => updatePreference('funcons_per_month', parseInt(e.target.value))}
            />
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default PreferencesPage;