import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link2, X, Check } from 'lucide-react';
import { ReactComponent as WavePattern } from '../assets/wave-pattern.svg';

const InviteFriendsDrawer = ({ isOpen, onClose }) => {
  const [copied, setCopied] = useState(false);
  const inviteLink = 'https://www.funcon.community/signup?invite=func0nl1v325';

  const copyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const DrawerContent = () => (
    <div className="bg-[#1A1A1A] p-6 rounded-t-xl md:rounded-xl w-full md:w-[480px] space-y-6 relative overflow-hidden">
      {/* Drag Handle */}
      <div className="absolute top-3 left-1/2 transform -translate-x-1/2 w-12 h-1 bg-[#B39DDB] rounded-full opacity-50 md:hidden" />
      
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-5">
        <WavePattern className="w-full h-full" />
      </div>
      
      {/* Content */}
      <div className="relative z-10 mt-4 md:mt-0">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-white">
            Invite friends to join you
          </h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-white/5 rounded-full"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <p className="text-gray-400 text-sm mb-6">
          Share this link with friends to join your next Funcon conversation
        </p>

        <div className="space-y-4">
          <div className="flex items-center gap-3 p-3 bg-black/20 rounded-lg border border-white/5">
            <div className="flex-1 truncate text-sm text-gray-300">
              {inviteLink}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={copyLink}
              className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-200 ${
                copied 
                  ? 'bg-green-500/10 text-green-400' 
                  : 'bg-[#B39DDB] text-white hover:bg-[#957DAD]'
              }`}
            >
              {copied ? (
                <>
                  <Check className="w-4 h-4" />
                  <span className="text-sm font-medium">Copied</span>
                </>
              ) : (
                <>
                  <Link2 className="w-4 h-4" />
                  <span className="text-sm font-medium">Copy</span>
                </>
              )}
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50"
          />
          
          <motion.div
            initial={{ 
              opacity: 0,
              y: window.innerWidth <= 768 ? "100%" : 20,
              x: window.innerWidth > 768 ? "-50%" : 0
            }}
            animate={{ 
              opacity: 1,
              y: 0,
              x: window.innerWidth > 768 ? "-50%" : 0
            }}
            exit={{ 
              opacity: 0,
              y: window.innerWidth <= 768 ? "100%" : 20,
              x: window.innerWidth > 768 ? "-50%" : 0
            }}
            className="fixed bottom-0 md:bottom-auto md:top-[20%] left-0 md:left-1/2 z-50 w-full md:w-auto"
          >
            <DrawerContent />
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default InviteFriendsDrawer; 