import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Search, X, Info } from 'lucide-react';
import { format } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

const TimezoneSelector = ({ selectedTimezone, onTimezoneChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [timezones, setTimezones] = useState([]);
  const [userTimezone, setUserTimezone] = useState('');

  useEffect(() => {
    const fetchedTimezones = Intl.supportedValuesOf('timeZone');
    const groupedTimezones = groupTimezones(fetchedTimezones);
    setTimezones(groupedTimezones);
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(detectedTimezone);
    if (!selectedTimezone) {
      onTimezoneChange(detectedTimezone);
    }
  }, []);

  const groupTimezones = (tzList) => {
    return tzList.reduce((acc, tz) => {
      const [continent, ...rest] = tz.split('/');
      if (!acc[continent]) {
        acc[continent] = [];
      }
      acc[continent].push(tz);
      return acc;
    }, {});
  };

  const filteredTimezones = useMemo(() => {
    const filtered = {};
    Object.entries(timezones).forEach(([continent, tzList]) => {
      const filteredList = tzList.filter(tz => 
        tz.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (filteredList.length > 0) {
        filtered[continent] = filteredList;
      }
    });
    return filtered;
  }, [timezones, searchQuery]);

  const getCurrentTime = (timezone) => {
    const now = new Date();
    const zonedTime = utcToZonedTime(now, timezone);
    return format(zonedTime, 'HH:mm');
  };

  return (
    <div className="relative w-full max-w-xs">
      <motion.button
        className="bg-gray-800 text-white px-4 py-2 rounded-full flex items-center justify-between w-full focus:outline-none focus:ring-2 focus:ring-purple-500"
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <span className="truncate">{selectedTimezone}</span>
        <ChevronDown size={16} className={`ml-2 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`} />
      </motion.button>
      <div className="mt-1 text-xs text-gray-400 flex items-center">
        <Info size={12} className="mr-1" />
        <span>Select your timezone to ensure accurate scheduling</span>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-10 mt-2 w-full bg-gray-800 rounded-2xl shadow-xl overflow-hidden"
          >
            <div className="p-2">
              <div className="relative">
                <Search size={18} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search timezones..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full bg-gray-700 text-white pl-10 pr-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                {searchQuery && (
                  <X
                    size={18}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer hover:text-white"
                    onClick={() => setSearchQuery('')}
                  />
                )}
              </div>
            </div>
            <div className="max-h-60 overflow-auto scrollbar-thin scrollbar-thumb-purple-500 scrollbar-track-gray-700">
              {Object.entries(filteredTimezones).map(([continent, tzList]) => (
                <div key={continent}>
                  <div className="px-4 py-2 font-semibold text-purple-300 bg-gray-900">{continent}</div>
                  {tzList.map((tz) => (
                    <motion.div
                      key={tz}
                      className={`px-4 py-2 cursor-pointer hover:bg-gray-700 transition-colors flex justify-between items-center ${tz === userTimezone ? 'bg-purple-900 hover:bg-purple-800' : ''}`}
                      onClick={() => {
                        onTimezoneChange(tz);
                        setIsOpen(false);
                        setSearchQuery('');
                      }}
                      whileHover={{ x: 5 }}
                    >
                      <span>{tz}</span>
                      <span className="text-sm text-gray-400">{getCurrentTime(tz)}</span>
                    </motion.div>
                  ))}
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TimezoneSelector;