import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FirstInviteBlogPost = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = 'https://www.funcon.community/blog/your-first-invite';
  }, []);

  return null;
};

export default FirstInviteBlogPost;