import React, { useContext, useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";
import useAxios from "../utils/axios";
import { AuthContext } from "../contexts/AuthContext";
import AddToCalendar from "../components/AddToCalendarDropdown";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as HandClock } from "../assets/icons/hand-clock.svg";
import { ReactComponent as AccountIcon } from "../assets/icons/account-circle.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Clock, Calendar, Plus, LucideAArrowDown } from 'lucide-react';
import { Search } from 'lucide-react';
import { ReactComponent as CreateFunconIcon } from '../assets/icons/create-funcon.svg';

const PendingInvitation = ({ funcon, fetchFuncons }) => {
  const { user } = useContext(AuthContext);
  const axiosInstance = useAxios();
  const navigate = useNavigate();

  const onAccept = () => {
    axiosInstance
      .put(`/api/user/${user.id}/funcons/${funcon.id}/response`, {
        accept: true,
      })
      .then(() => {
        toast.success("Invite accepted successfully", {
          onClose: () => {
            navigate(`/view-funcon/${funcon.id}`);
          },
          autoClose: 1500, // Wait 1.5 seconds before redirecting
        });
        fetchFuncons(); // Still update the list in the background
      })
      .catch((error) => {
        toast.error("Failed to accept invite. Please try again.");
      });
  };
  const onDecline = () => {
    axiosInstance
      .put(`/api/user/${user.id}/funcons/${funcon.id}/response`, {
        accept: false,
      })
      .then(() => {
        fetchFuncons();
        toast.success("Invite has declined successfully");
      });
  };

  const receivedTime = new Date(funcon.scheduled_datetime);

  const options = {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZone: "America/New_York",
    timeZoneName: "short",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(receivedTime);

  const currentTime = new Date();
  
  const timeDifference = receivedTime - currentTime;

  const hoursLeft = Math.floor(Math.random() * 48) + 1;

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="group"
    >
      <div className="py-6">
        <Link
          to={`/view-funcon/${funcon.id}`}
          className="flex items-center gap-2 group mb-3"
        >
          <span className="text-[#B39DDB] text-base">
            {formattedDate}, View Invite
          </span>
          <motion.span 
            className="text-[#B39DDB]/50 text-sm"
            whileHover={{ x: 2 }}
          >
            ↗
          </motion.span>
        </Link>

        <div className="flex items-center justify-between">
          <span className="flex items-center gap-2.5">
            <HandClock className="w-5 h-5 text-gray-400" />
            <span className="text-sm text-gray-400">
              {`${hoursLeft}h left to respond`}
            </span>
          </span>
          <div className="flex gap-4">
            <button
              onClick={onAccept}
              className="flex items-center gap-2.5 px-4 py-2 rounded-lg
                bg-green-500/10 hover:bg-green-500/20 
                border border-green-500/20
                text-green-400 transition-all duration-200"
            >
              <Check className="w-5 h-5" />
              <span className="text-sm font-medium">Accept</span>
            </button>

            <button
              onClick={onDecline}
              className="flex items-center gap-2.5 px-4 py-2 rounded-lg
                bg-red-500/10 hover:bg-red-500/20 
                border border-red-500/20
                text-red-400 transition-all duration-200"
            >
              <Close className="w-5 h-5" />
              <span className="text-sm font-medium">Decline</span>
            </button>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-gray-800" />
    </motion.div>
  );
};

const AcceptedFuncon = ({ funcon }) => {
  const { user } = useContext(AuthContext);
  const isHost = funcon.host.id === user.id;
  
  const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/New_York",
      timeZoneName: "short"
    }).format(date).replace(',', ' at');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="group"
    >
      <div className="py-3">
        <div className={`${isHost ? 'border border-[#B39DDB] bg-[#333333] rounded-md px-4 py-3' : ''}`}>
          <div className="w-full">
            <Link
              to={`/view-funcon/${funcon.id}`}
              className="group block mb-2"
            >
              <p className="text-[#B39DDB] text-base leading-6 flex">
                <span className="group-hover:underline decoration-[#B39DDB] line-clamp-2 flex-1">
                  {funcon.topic.description}
                </span>
                <span className="text-[#B39DDB]/50 flex-shrink-0">↗</span>
              </p>
            </Link>

            <div className="flex flex-wrap items-center gap-x-6 gap-y-3">
              <span className="flex items-center gap-2.5">
                <AccountIcon className="w-5 h-5 text-gray-400" />
                <span className="text-sm text-gray-400">
                  {isHost ? "You are the host" : `${funcon.host.first_name} ${funcon.host.last_name}`}
                </span>
              </span>

              <span className="flex items-center gap-2.5">
                <Clock className="w-5 h-5 text-gray-400" />
                <span className="text-sm text-gray-400">
                  {formatDateTime(funcon.scheduled_datetime)}
                </span>
              </span>

              {funcon?.accepted && (
                <span className="flex items-center gap-2.5">
                  <AddToCalendar funcon={funcon}>
                    <span className="flex items-center gap-2 text-[#B39DDB] hover:text-[#C7B4E2] transition-colors duration-200 cursor-pointer">
                      <Calendar className="w-5 h-5" />
                      <span className="text-sm">Add to Calendar</span>
                    </span>
                  </AddToCalendar>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-gray-800/80" />
    </motion.div>
  );
};

const Upcoming = () => {
  const [funcons, setFuncons] = useState(null);
  const { user } = useContext(AuthContext);
  const axiosInstance = useAxios();
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getUserSpecificFuncons = () => {
    setIsLoading(true);
    axiosInstance.get(`/api/user/${user.id}/funcons`)
      .then((result) => {
        setFuncons(result.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserSpecificFuncons();
  }, []);

  const pendingFuncons = funcons?.filter(
    (funcon) =>
      !funcon.accepted && funcon.status === "scheduled" && !funcon.declined
  );

  const acceptedFuncons = funcons?.filter(
    (funcon) =>
      funcon.accepted && funcon.status === "scheduled" && !funcon.declined
  );

  const groupedFuncons = funcons?.reduce((groups, funcon) => {
    const date = new Date(funcon.scheduled_datetime).toLocaleDateString();
    if (!groups[date]) groups[date] = [];
    groups[date].push(funcon);
    return groups;
  }, {}) || {};

  const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "America/New_York",
      timeZoneName: "short"
    }).format(date).replace(',', ' at');
  };

  // Sort funcons
  const sortedPendingFuncons = useMemo(() => 
    pendingFuncons?.sort((a, b) => 
      new Date(a.scheduled_datetime) - new Date(b.scheduled_datetime)
    ), [pendingFuncons]
  );

  const sortedAcceptedFuncons = acceptedFuncons?.sort((a, b) => 
    new Date(a.scheduled_datetime) - new Date(b.scheduled_datetime)
  );

  const handleInviteDrawerState = (state) => {
    setIsInviteOpen(state);
  };

  return (
    <div className="min-h-screen bg-[#1a1a1a] font-inter">
      <Navbar 
        onInviteDrawerChange={handleInviteDrawerState}
        onMenuChange={setIsMenuOpen}
      />
      <main
        className={`
          min-h-screen
          pt-20 md:pt-8
          ${isMenuOpen ? 'pl-0' : 'md:pl-96'}
          transition-all duration-300
        `}
      >
        <div className="px-4 sm:px-6 lg:px-8 py-8 max-w-7xl mx-auto">
          {/* Header section */}
          <motion.div
            className="space-y-3 mb-8 sm:mb-12 max-w-4xl w-full mt-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-[36px] sm:text-[36px] lg:text-[36px] leading-[1.2] font-bold text-[#F5F5F5]">
              My Funcons
            </h1>
            <p className="text-[#B39DDB] text-left font-semibold">
              View and manage your upcoming Funcons.
            </p>
          </motion.div>

          {/* Content sections - adjusted max-width */}
          {isLoading ? (
            <motion.div 
              className="w-full flex justify-center items-center py-12"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <div className="flex flex-col items-center gap-4">
                <div className="relative w-16 h-16">
                  <motion.div
                    className="absolute inset-0 rounded-full border-2 border-[#B39DDB]/20 border-t-[#B39DDB]"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  />
                  <motion.div
                    className="absolute inset-2 rounded-full border-2 border-[#B39DDB]/10 border-t-[#B39DDB]/60"
                    animate={{ rotate: -360 }}
                    transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
                  />
                  <motion.div
                    className="absolute inset-4 rounded-full border-2 border-[#B39DDB]/5 border-t-[#B39DDB]/40"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                  />
                </div>
                <motion.p 
                  className="text-gray-400 text-lg"
                  animate={{ opacity: [0.5, 1, 0.5] }}
                  transition={{ duration: 2, repeat: Infinity }}
                >
                  Loading Funcons...
                </motion.p>
              </div>
            </motion.div>
          ) : (
            funcons && (
              <div className="space-y-8 sm:space-y-12 w-full">
                <section className="w-full max-w-3xl">
                  <div className="flex items-center gap-3 mb-6">
                    <div className="h-8 w-8 rounded-full bg-yellow-500/10 flex items-center justify-center">
                      <Clock className="w-4 h-4 text-yellow-400" />
                    </div>
                    <h2 className="mb-0 text-2xl font-semibold text-gray-200">
                      Pending Responses
                    </h2>
                    {Boolean(pendingFuncons?.length) && (
                      <span className="ml-auto px-3 py-1 rounded-full bg-yellow-500/10 text-yellow-300 text-sm">
                        {pendingFuncons.length}
                      </span>
                    )}
                  </div>
                  <div className="max-w-3xl">
                    {pendingFuncons?.length > 0 ? (
                      sortedPendingFuncons?.map((pendingFuncon) => (
                        <PendingInvitation
                          key={pendingFuncon.id}
                          funcon={pendingFuncon}
                          fetchFuncons={getUserSpecificFuncons}
                        />
                      ))
                    ) : (
                      <p className="text-gray-400 text-center py-8">
                        No pending invites. Check back later or email us if you want more Funcon invites :)
                      </p>
                    )}
                  </div>
                </section>

                {Boolean(acceptedFuncons.length) && (
                  <section className="w-full max-w-3xl">
                    <div className="flex items-center justify-between mb-6">
                      <div className="flex items-center gap-3">
                        <div className="h-8 w-8 rounded-full bg-green-500/10 flex items-center justify-center translate-y-[1px]">
                          <Check className="w-4 h-4 text-green-400" />
                        </div>
                        <h2 className="text-2xl font-semibold text-gray-200">
                          Accepted Funcons
                        </h2>
                      </div>
                      <span className="px-3 py-1 rounded-full bg-green-500/10 text-green-300 text-sm">
                        {acceptedFuncons.length}
                      </span>
                    </div>
                    <div className="max-w-3xl">
                      {sortedAcceptedFuncons?.map((acceptedFuncon) => (
                        <AcceptedFuncon
                          key={acceptedFuncon.id}
                          funcon={acceptedFuncon}
                        />
                      ))}
                    </div>
                  </section>
                )}

                {/* Empty state - adjusted width */}
                {funcons.length === 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="w-full text-center py-12 sm:py-20"
                  >
                    <Calendar className="w-16 h-16 text-gray-600 mx-auto mb-4" />
                    <p className="text-xl text-gray-400 mb-6">No upcoming Funcons yet</p>
                    <Link
                      to="/host"
                      className={`
                        fixed bottom-4 right-4 
                        sm:bottom-8 sm:right-8 
                        md:right-12 md:bottom-12
                        z-50 flex items-center gap-2 
                        px-4 sm:px-6 py-3 
                        bg-[#B39DDB] rounded-[8px] text-white 
                        shadow-lg 
                        transition-all duration-200 
                        hover:bg-[#9B7FC3] hover:scale-105 hover:shadow-xl
                        active:scale-95
                        group
                        ${isInviteOpen || isMenuOpen ? 'hidden' : ''}
                      `}
                    >
                      <CreateFunconIcon className="w-5 h-5 sm:w-6 sm:h-6 group-hover:rotate-90 transition-transform duration-200" />
                      <span className="text-sm sm:text-base font-inter font-medium">Create Funcon</span>
                    </Link>
                  </motion.div>
                )}
              </div>
            )
          )}
        </div>
      </main>

      {/* Create Funcon button - adjusted positioning */}
      <Link
        to="/host"
        className={`
          fixed bottom-4 right-4 
          sm:bottom-8 sm:right-8 
          md:right-12 md:bottom-12
          z-50 flex items-center gap-2 
          px-4 sm:px-6 py-3 
          bg-[#B39DDB] rounded-[8px] text-white 
          shadow-lg 
          transition-all duration-200 
          hover:bg-[#9B7FC3] hover:scale-105 hover:shadow-xl
          active:scale-95
          group
          ${isInviteOpen || isMenuOpen ? 'hidden' : ''}
        `}
      >
        <CreateFunconIcon className="w-5 h-5 sm:w-6 sm:h-6 group-hover:rotate-90 transition-transform duration-200" />
        <span className="text-sm sm:text-base font-inter font-medium">Create Funcon</span>
      </Link>

      <Footer />
      <ToastContainer
        position="bottom-center"
        theme="dark"
        toastClassName="bg-gray-800 text-white"
      />
    </div>
  );
};

export default Upcoming;
