import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FaBars, FaTimes } from 'react-icons/fa';
import FunconLogo from '../assets/funcon-logo-horizontal.svg';
import { motion } from 'framer-motion';

const NavigationBar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/');
    window.location.reload();
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const NavLink = ({ to, onClick, children }) => (
    <Link
      to={to}
      onClick={onClick}
      className="block py-2 px-4 text-gray-300 hover:text-white transition duration-300"
    >
      {children}
    </Link>
  );

  return (
    <nav className="bg-[#1A1A1A] text-white border-b border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <img src={FunconLogo} alt="Funcon Logo" className="h-8 w-auto" />
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {!user && <NavLink to="/cookbook">About</NavLink>}
              {user && (
                <>
                  <NavLink to="/preferences">Preferences</NavLink>
                  <NavLink to="/cookbook">Cookbook</NavLink>
                  {user.is_superuser && (
                    <NavLink to="/admin">Admin</NavLink>
                  )}
                  <NavLink as="button" onClick={handleLogout}>
                    Logout
                  </NavLink>
                </>
              )}
              {!user && (
                <NavLink to="/login" className="text-purple-400 hover:text-purple-300">
                  Sign In
                </NavLink>
              )}
            </div>
          </div>
          <div className="md:hidden mr-2">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              {isMenuOpen ? <FaTimes className="h-6 w-6" /> : <FaBars className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-[#1A1A1A] z-40 pt-20 pb-20 md:hidden overflow-y-auto px-4"
        >
          <div className="flex flex-col space-y-2">
            {!user && <NavLink to="/cookbook">About</NavLink>}
            {user && (
              <>
                <NavLink to="/preferences">Preferences</NavLink>
                <NavLink to="/cookbook">Cookbook</NavLink>
                {user.is_superuser && (
                  <NavLink to="/admin">Admin</NavLink>
                )}
                <NavLink as="button" onClick={handleLogout}>
                  Logout
                </NavLink>
              </>
            )}
            {!user && (
              <NavLink to="/login" className="text-purple-400 hover:text-purple-300">
                Sign In
              </NavLink>
            )}
          </div>
        </motion.div>
      )}
    </nav>
  );
};

export default NavigationBar;
