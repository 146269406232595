import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, AnimatePresence, useSpring, useTransform } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const words = ["Think", "Explore", "Question", "Connect", "Propel"];

const LandingPage = () => {
  const [currentWord, setCurrentWord] = useState(0);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length);
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  const handleMouseMove = useCallback((event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  }, []);

  return (
    <>
    <Helmet>
      <title>Funcon - Discover Meaningful Conversations | Join Our Community</title>
      <meta name="description" content="Join Funcon and engage in intimate, thought-provoking conversations. Explore diverse perspectives, foster personal growth, and build meaningful connections." />
    </Helmet>
    <div 
      className="bg-[#1a1a1a] text-white font-inter min-h-screen flex flex-col justify-between p-8 relative overflow-hidden"
      onMouseMove={handleMouseMove}
    >
      <nav className="flex justify-between items-center z-10">
        <motion.a
          href="/cookbook"
          className="text-gray-300 hover:text-white transition-colors duration-300 text-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          About
        </motion.a>
        <motion.a
          href="/login"
          className="text-gray-300 hover:text-white transition-colors duration-300 text-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Sign In
        </motion.a>
      </nav>

      <main className="flex flex-col items-center justify-center flex-grow z-10">
        <motion.h1 
          className="text-5xl sm:text-6xl md:text-7xl font-bold mb-8 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Funcon 🫶
        </motion.h1>

        <div className="h-24 mb-12 flex justify-center items-center overflow-hidden">
          <AnimatePresence mode="wait">
            <motion.span
              key={currentWord}
              className="text-4xl sm:text-5xl md:text-6xl text-purple-400 absolute"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              {words[currentWord]}
            </motion.span>
          </AnimatePresence>
        </div>

        <motion.p 
          className="text-xl sm:text-2xl mb-12 max-w-2xl mx-auto text-gray-300 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          Intimate discourse that propels us in self discovery
        </motion.p>

        <motion.button
          className="bg-purple-600 text-white px-10 py-4 rounded-full text-xl font-medium transition-all duration-300 ease-in-out shadow-lg hover:shadow-purple-500/50"
          whileHover={{ scale: 1.05, backgroundColor: "#9333ea" }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/signup')}
        >
          Join Us
        </motion.button>
      </main>

      <footer className="text-center z-10">
        <p className="text-sm text-gray-500">
          &copy; 2024 Funcon. Real Conversations. 
          <a href="https://funcon.community/tos" className="ml-2 text-gray-400 hover:text-gray-300">Terms</a>
          <span className="mx-1">·</span>
          <a href="https://funcon.community/privacy" className="text-gray-400 hover:text-gray-300">Privacy</a>
        </p>
      </footer>

      <NebulaBackground currentWord={currentWord} />
      <BrilliantParticles mousePosition={mousePosition} />
    </div>
    </>
  );
};

const NebulaBackground = ({ currentWord }) => {
  const colors = [
    'rgba(139,92,246,0.2)',
    'rgba(59,130,246,0.2)',
    'rgba(236,72,153,0.2)',
    'rgba(16,185,129,0.2)',
    'rgba(245,158,11,0.2)'
  ];

  return (
    <motion.div 
      className="absolute inset-0 z-0"
      animate={{
        background: `
          radial-gradient(ellipse at top left, ${colors[currentWord]} 0%, transparent 50%),
          radial-gradient(ellipse at bottom right, ${colors[(currentWord + 1) % 5]} 0%, transparent 50%),
          radial-gradient(ellipse at center, ${colors[(currentWord + 2) % 5]} 0%, transparent 60%)
        `
      }}
      transition={{ duration: 2 }}
    />
  );
};

const BrilliantParticles = ({ mousePosition }) => {
  const particles = useMemo(() => {
    return [...Array(80)].map(() => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      size: Math.random() * 3 + 2, // Increased size range
    }));
  }, []);

  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {particles.map((particle, i) => (
        <BrilliantParticle key={i} initialPosition={particle} mousePosition={mousePosition} />
      ))}
    </div>
  );
};

const BrilliantParticle = ({ initialPosition, mousePosition }) => {
  const springConfig = { damping: 40, stiffness: 90, mass: 1 };
  const x = useSpring(initialPosition.x, springConfig);
  const y = useSpring(initialPosition.y, springConfig);

  const scale = useTransform(
    [x, y],
    ([latestX, latestY]) => {
      const distance = Math.sqrt(
        Math.pow(mousePosition.x - latestX, 2) + 
        Math.pow(mousePosition.y - latestY, 2)
      );
      return 1 + Math.max(0, (300 - distance) / 150);
    }
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const angle = Math.random() * Math.PI * 2;
      const force = Math.random() * 50;
      x.set(x.get() + Math.cos(angle) * force);
      y.set(y.get() + Math.sin(angle) * force);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [x, y]);

  useEffect(() => {
    const distX = mousePosition.x - x.get();
    const distY = mousePosition.y - y.get();
    const distance = Math.sqrt(distX * distX + distY * distY);
    const maxDistance = 300;

    if (distance < maxDistance) {
      const force = (maxDistance - distance) / maxDistance;
      const angle = Math.atan2(distY, distX) + Math.PI;
      const targetX = x.get() + Math.cos(angle) * force * 60;
      const targetY = y.get() + Math.sin(angle) * force * 60;
      x.set(targetX);
      y.set(targetY);
    }
  }, [mousePosition, x, y]);

  return (
    <>
    <Helmet>
      <title>Funcon - Discover Meaningful Conversations | Join Our Community</title>
      <meta name="description" content="Join Funcon and engage in intimate, thought-provoking conversations. Explore diverse perspectives, foster personal growth, and build meaningful connections." />
    </Helmet>
    <motion.div
      className="absolute rounded-full bg-white"
      style={{
        width: initialPosition.size,
        height: initialPosition.size,
        x,
        y,
        scale,
      }}
      animate={{
        opacity: [0.3, 0.8, 0.3],
        boxShadow: [
          '0 0 0 0 rgba(255,255,255,0)',
          '0 0 10px 2px rgba(255,255,255,0.5)',
          '0 0 0 0 rgba(255,255,255,0)'
        ]
      }}
      transition={{
        duration: Math.random() * 3 + 2,
        repeat: Infinity,
        ease: "easeInOut",
      }}
    />
  </>
  );
};

export default LandingPage;