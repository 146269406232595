// TopicGenerator.js

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Sparkles,
  HelpCircle,
  MessageCircle,
  BookOpen,
  ChevronRight,
  X,
  ChevronLeft,
} from 'lucide-react';
import CustomCalendar from '../components/CustomCalendar';

const TopicGenerator = ({ onComplete, funconData, timezone }) => {
  const [topic, setTopic] = useState(funconData.topic || '');
  const [stage, setStage] = useState('input');
  // const [showHelp, setShowHelp] = useState(false);
  const [showAssistanceCalendar, setShowAssistanceCalendar] = useState(false);
  const [error, setError] = useState('');

  const handleGenerateTopic = () => {
    if (topic.trim().length > 1000) {
      setError('Topic must be 1000 characters or less.');
    } else if (topic.trim()) {
      setError('');
      setStage('confirmation');
    } else {
      setError('Please enter a topic.');
    }
  };

  const handleConfirm = () => {
    onComplete({ topic });
  };

  const handleTopicChange = (e) => {
    setTopic(e.target.value);
    if (e.target.value.length > 1000) {
      setError('Topic must be 1000 characters or less.');
    } else {
      setError('');
    }
  };

  const helpSections = {
    main: {
      title: 'How can we help?',
      content: [
        {
          icon: MessageCircle,
          text: 'Chat with us 1:1',
          action: () => setShowAssistanceCalendar(true),
        },
        {
          icon: BookOpen,
          text: 'Read hosting tips',
          action: () => window.open('/cookbook', '_blank'),
        },
      ],
    },
  };

  // const HelpContent = () => (
  //   <motion.div
  //     initial={{ opacity: 0, scale: 0.95 }}
  //     animate={{ opacity: 1, scale: 1 }}
  //     exit={{ opacity: 0, scale: 0.95 }}
  //     transition={{ duration: 0.2 }}
  //     className="absolute inset-0 bg-black bg-opacity-90 backdrop-blur-sm flex items-center justify-center z-10"
  //   >
  //     {showAssistanceCalendar ? (
  //       <div className="w-full max-w-md">
  //         <span
  //           onClick={() => setShowAssistanceCalendar(false)}
  //           className="mb-4 text-purple-400 hover:text-purple-300 transition-colors duration-300 flex items-center cursor-pointer"
  //         >
  //           <ChevronLeft size={20} />
  //           <span className="ml-1">Back to help options</span>
  //         </span>
  //         <CustomCalendar
  //           onDateSelect={() => {}}
  //           onComplete={() => {
  //             setShowAssistanceCalendar(false);
  //             // setShowHelp(false);
  //           }}
  //           mode="assistance"
  //           userTimezone={timezone}
  //         />
  //       </div>
  //     ) : (
  //       <motion.div
  //         className="bg-gray-900 rounded-3xl p-6 w-full max-w-sm relative overflow-hidden"
  //         layoutId="help-container"
  //       >
  //         <motion.div
  //           className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors cursor-pointer"
  //           whileHover={{ scale: 1.1 }}
  //           whileTap={{ scale: 0.9 }}
  //           onClick={() => setShowHelp(false)}
  //         >
  //           <X size={24} />
  //         </motion.div>

  //         <h3 className="text-2xl font-light mb-6 text-white">
  //           {helpSections.main.title}
  //         </h3>
  //         <div className="space-y-4">
  //           {helpSections.main.content.map((item, index) => (
  //             <motion.div
  //               key={index}
  //               className="flex items-center space-x-4 text-gray-300 hover:text-white transition-colors cursor-pointer"
  //               onClick={item.action}
  //               whileHover={{ x: 5 }}
  //             >
  //               {item.icon && <item.icon size={24} />}
  //               <span className="text-lg">{item.text}</span>
  //               <ChevronRight size={20} className="ml-auto" />
  //             </motion.div>
  //           ))}
  //         </div>
  //       </motion.div>
  //     )}
  //   </motion.div>
  // );

  return (
    <div className="space-y-6 relative">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-3xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-purple-300 via-purple-500 to-indigo-400">
          Topic
        </h2>
        {/* <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="text-purple-400 hover:text-purple-300 transition-colors cursor-pointer"
          onClick={() => setShowHelp(true)}
        >
          <HelpCircle size={24} />
        </motion.div> */}
      </div>

      <AnimatePresence mode="wait">
        {stage === 'input' && (
          <motion.div
            key="input"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="relative group mb-8">
              <motion.textarea
                value={topic}
                onChange={handleTopicChange}
                placeholder="What's on your mind? Start with a story, introduce an idea, end with a question..."
                className={`w-full h-40 bg-gray-800 bg-opacity-50 rounded-xl border-2 ${
                  error ? 'border-red-500' : 'border-purple-700'
                } focus:border-purple-500 focus:ring-2 focus:ring-purple-500 text-white placeholder-gray-400 py-4 px-6 text-lg resize-none transition-all duration-300`}
                whileFocus={{ scale: 1.02 }}
                transition={{ type: 'spring', stiffness: 300, damping: 20 }}
              />
              <Sparkles
                className="absolute right-4 bottom-4 text-purple-400 opacity-50 transition-opacity duration-300 group-hover:opacity-100"
                size={24}
              />
            </div>
            {error && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-red-500 mb-4"
              >
                {error}
              </motion.p>
            )}
            <p className="text-gray-400 mb-4">
              {topic.length}/1000 characters
            </p>
            <span
              onClick={handleGenerateTopic}
              className={`block w-full py-4 text-center ${
                error
                  ? 'bg-gray-500 cursor-not-allowed'
                  : 'bg-gradient-to-r from-purple-500 to-indigo-600 hover:from-purple-600 hover:to-indigo-700 cursor-pointer'
              } text-white rounded-full text-lg font-medium transition duration-300`}
            >
              Continue
            </span>
          </motion.div>
        )}

        {stage === 'confirmation' && (
          <motion.div
            key="confirmation"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="bg-gray-800 bg-opacity-50 p-6 rounded-xl mb-8">
              <h3 className="text-xl font-semibold mb-4">Your Funcon Topic</h3>
              <p className="text-lg text-gray-300 whitespace-pre-wrap break-words">{topic}</p>
            </div>

            <div className="flex space-x-4">
              <span
                onClick={handleConfirm}
                className="flex-1 py-4 text-center bg-gradient-to-r from-purple-500 to-indigo-600 text-white rounded-full text-lg font-medium hover:from-purple-600 hover:to-indigo-700 transition duration-300 cursor-pointer"
              >
                Confirm
              </span>
              <span
                onClick={() => setStage('input')}
                className="flex-1 py-4 text-center bg-gray-700 text-white rounded-full text-lg font-medium hover:bg-gray-600 transition duration-300 cursor-pointer"
              >
                Edit Topic
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* <AnimatePresence>{showHelp && <HelpContent />}</AnimatePresence> */}
    </div>
  );
};

export default TopicGenerator;
