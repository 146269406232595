import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Users, BookOpen, User, Book, UserPlus, Menu, ChevronRight, Globe, PenTool } from 'lucide-react';
import { ReactComponent as Logo } from '../assets/funcon-logo-horizontal.svg';
import { useAuth } from '../contexts/AuthContext';
import InviteFriendsDrawer from './InviteFriendsDrawer';
import { toast } from 'react-toastify';
import { Plus, Calendar } from 'lucide-react';
import { FaTimes, FaBars } from 'react-icons/fa';

const SubMenuItem = ({ to, icon: Icon, title, subtitle, onClick }) => (
  <div onClick={onClick} className="w-full">
    <motion.div
      className="flex items-center justify-between px-6 py-4 transition-colors duration-200 hover:bg-[#B39DDB]/10"
    >
      <div className="flex items-center gap-4">
        <div className="w-8 h-8 flex items-center justify-center">
          <Icon className="w-8 h-8 text-[#B39DDB]" />
        </div>
        <div className="flex flex-col">
          <span className="text-[16px] font-semibold text-white">{title}</span>
          <span className="text-[14px] text-[#B0B0B0]">{subtitle}</span>
        </div>
      </div>
      <div className="w-8 h-8 flex items-center justify-center">
        <ChevronRight className="w-8 h-8 text-white" />
      </div>
    </motion.div>
  </div>
);

const NavItem = ({ 
  to, 
  icon: Icon, 
  title, 
  subtitle, 
  isActive, 
  onClick, 
  children, 
  isVisible = true,
  navigate,
  setIsMenuOpen,
  setExpandedSection 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!isVisible) return null;

  const baseItemStyles = `
    flex items-center justify-between p-6 transition-all duration-300 w-full
    ${isActive ? 'bg-[#B39DDB]/15' : 'hover:bg-[#B39DDB]/10'}
    group relative overflow-hidden border-b border-dashed border-[#B39DDB]/30
    max-w-full
  `;

  const titleStyles = `
    text-[24px] font-semibold font-inter transition-colors duration-300
    ${isActive ? 'text-[#B39DDB]' : 'text-gray-200'}
  `;

  const subtitleStyles = `
    text-[16px] font-normal text-[#B0B0B0] transition-colors duration-300
  `;

  if (children) {
    return (
      <div className="w-full border-b border-dashed border-[#B39DDB]/30">
        <motion.div
          onClick={() => setIsExpanded(!isExpanded)}
          className={baseItemStyles}
        >
          <div className="flex flex-col flex-grow">
            <span className={titleStyles}>{title}</span>
            {subtitle && (
              <span className={subtitleStyles}>{subtitle}</span>
            )}
          </div>
          <ChevronRight 
            className={`w-8 h-8 text-white transition-all duration-300
            ${isExpanded ? 'rotate-90' : ''}`}
          />
        </motion.div>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="bg-[#1E1E1E]"
            >
              <div className="py-2 space-y-2">
                {title === "Funcon" ? (
                  <>
                    <SubMenuItem
                      to="/host"
                      icon={Plus}
                      title="Create Funcon"
                      subtitle="We curate an eclectic group of people to explore your questions"
                      onClick={() => {
                        navigate('/host');
                        setIsMenuOpen(false);
                        setExpandedSection(null);
                      }}
                    />
                    <SubMenuItem
                      to="/upcoming"
                      icon={Calendar}
                      title="My Funcons"
                      subtitle="View all your invites and upcoming Funcons"
                    />
                  </>
                ) : title === "Community" ? (
                  <>
                    <SubMenuItem
                      to="#"
                      icon={Globe}
                      title="Live Funcon"
                      subtitle="Funconners from all over the world come together for 3 days"
                      onClick={() => toast.info("Live Funcon Coming Soon!!")}
                    />
                    <SubMenuItem
                      to="#"
                      icon={Users}
                      title="MY Retreat"
                      subtitle="1 week spiritual retreat with other Funconners"
                      onClick={() => toast.info("MY Retreat Coming Soon!!")}
                    />
                    <SubMenuItem
                      to="#"
                      icon={PenTool}
                      title="Book"
                      subtitle="The Funcon community is writing an open-source book"
                      onClick={() => toast.info("Book Coming Soon!!")}
                    />
                  </>
                ) : null}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }

  return (
    <Link to={to} onClick={onClick} className="w-full border-b border-dashed border-[#B39DDB]/30">
      <motion.div className={baseItemStyles}>
        <div className="flex flex-col flex-grow">
          <span className={titleStyles}>{title}</span>
          {subtitle && (
            <span className={subtitleStyles}>{subtitle}</span>
          )}
        </div>
        <ChevronRight className="w-8 h-8 text-white" />
      </motion.div>
    </Link>
  );
};

const Navbar = ({ onInviteDrawerChange, onMenuChange }) => {
  const location = useLocation();
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if(onMenuChange){
      onMenuChange(isMenuOpen)
    }
  },[isMenuOpen])
  
  useEffect(() => {
    if (onInviteDrawerChange) {
      onInviteDrawerChange(isInviteOpen);
    }
  }, [isInviteOpen, onInviteDrawerChange]);

  return (
    <>
      {/* Desktop Navbar */}
      <nav className={`fixed left-0 top-0 h-screen w-96 bg-[#1a1a1a] border-r border-gray-800/50 flex flex-col z-50 transition-all duration-300 overflow-hidden hidden md:flex`}>
        <div className="flex flex-col h-full">
          <Link to="/" className="flex items-center px-6 py-4 border-b border-dashed border-[#B39DDB]/30">
            <Logo className="w-18 h-8" />
          </Link>
          <div className="flex-1 flex flex-col">
            <NavItem
              to="/"
              icon={BookOpen}
              title="Funcon"
              subtitle="Explore knowledge with curated conversations"
              isActive={location.pathname === '/'}
              onClick={() => {
                setExpandedSection(expandedSection === 'funcon' ? null : 'funcon');
                setIsMenuOpen(false);
              }}
              isVisible={!expandedSection || expandedSection === 'funcon'}
              navigate={navigate}
              setIsMenuOpen={setIsMenuOpen}
              setExpandedSection={setExpandedSection}
            >
              <SubMenuItem
                to="/host"
                icon={Plus}
                title="Create Funcon"
                subtitle="We curate an eclectic group of people to explore your questions"
                onClick={() => {
                  navigate('/host');
                  setIsMenuOpen(false);
                  setExpandedSection(null);
                }}
              />
              <SubMenuItem
                to="/upcoming"
                icon={Calendar}
                title="My Funcons"
                subtitle="View all your invites and upcoming Funcons"
              />
            </NavItem>
            <NavItem
              icon={Users}
              title="Community"
              subtitle="Shared modalities of self-discovery"
              onClick={() => {
                setExpandedSection(expandedSection === 'community' ? null : 'community');
                setIsMenuOpen(false);
              }}
              isVisible={!expandedSection || expandedSection === 'community'}
              navigate={navigate}
              setIsMenuOpen={setIsMenuOpen}
              setExpandedSection={setExpandedSection}
            >
              <SubMenuItem
                to="#"
                icon={Globe}
                title="Live Funcon"
                subtitle="Funconners from all over the world come together for 3 days"
                onClick={() => toast.info("Live Funcon Coming Soon!!")}
              />
              <SubMenuItem
                to="#"
                icon={Users}
                title="MY Retreat"
                subtitle="1 week spiritual retreat with other Funconners"
                onClick={() => toast.info("MY Retreat Coming Soon!!")}
              />
              <SubMenuItem
                to="#"
                icon={PenTool}
                title="Book"
                subtitle="The Funcon community is writing an open-source book"
                onClick={() => toast.info("Book Coming Soon!!")}
              />
            </NavItem>
            
            <NavItem
              to="/cookbook"
              icon={Book}
              title="Resources"
              isActive={location.pathname === '/cookbook'}
              isVisible={!expandedSection}
              navigate={navigate}
              setIsMenuOpen={setIsMenuOpen}
              setExpandedSection={setExpandedSection}
            />
            
            <NavItem
              to="/preferences"
              icon={User}
              title="My Account"
              isActive={location.pathname === '/preferences'}
              isVisible={!expandedSection}
              navigate={navigate}
              setIsMenuOpen={setIsMenuOpen}
              setExpandedSection={setExpandedSection}
            />
          </div>
          <div className="p-6 mt-auto">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setIsInviteOpen(true)}
              className="w-full flex items-center justify-center gap-2 px-4 py-4 
                bg-[#B39DDB]
                rounded-md text-white font-medium shadow-lg 
                hover:bg-[#9575CD]
                transition-all duration-200"
            >
              <UserPlus className="w-5 h-5" />
              <span className="text-base font-medium">Invite Friend</span>
            </motion.button>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar - Always visible */}
      <div className="fixed top-0 left-0 right-0 flex items-center justify-between h-16 px-6 bg-[#1a1a1a] border-b border-gray-800 z-50 md:hidden">
        <Link to="/" className="flex items-center">
          <Logo className="h-6 ml-2" />
        </Link>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="p-2 rounded-md border border-gray-800/50 hover:bg-gray-800/50 transition-colors mr-2"
        >
          {isMenuOpen ? <FaTimes className="w-5 h-5 text-gray-300" /> : <FaBars className="w-5 h-5 text-gray-300" />}
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-[#1A1A1A] z-40 pt-20 pb-4 md:hidden overflow-y-auto px-4 w-full"
          >
            <div className="flex flex-col space-y-2 max-w-full h-full">
              <NavItem
                to="/"
                icon={BookOpen}
                title="Funcon"
                subtitle="Explore knowledge with curated conversations"
                isActive={location.pathname === '/'}
                navigate={navigate}
                setIsMenuOpen={setIsMenuOpen}
                setExpandedSection={setExpandedSection}
              >
                <Link to="/host" className="block px-4 py-3 text-gray-300 hover:text-white hover:bg-[#B39DDB]/10">
                  Create
                </Link>
                <Link to="/upcoming" className="block px-4 py-3 text-gray-300 hover:text-white hover:bg-[#B39DDB]/10">
                  View My Funcons
                </Link>
              </NavItem>
              
              <NavItem
                icon={Users}
                title="Community"
                subtitle="Shared modalities of self-discovery"
                navigate={navigate}
                setIsMenuOpen={setIsMenuOpen}
                setExpandedSection={setExpandedSection}
              >
                <SubMenuItem
                  to="#"
                  icon={Globe}
                  title="Live Funcon"
                  subtitle="Funconners from all over the world come together for 3 days"
                  onClick={() => toast.info("Live Funcon Coming Soon!!")}
                />
                <SubMenuItem
                  to="#"
                  icon={Users}
                  title="MY Retreat"
                  subtitle="1 week spiritual retreat with other Funconners"
                  onClick={() => toast.info("MY Retreat Coming Soon!!")}
                />
                <SubMenuItem
                  to="#"
                  icon={PenTool}
                  title="Book"
                  subtitle="The Funcon community is writing an open-source book"
                  onClick={() => toast.info("Book Coming Soon!!")}
                />
              </NavItem>
              
              <NavItem
                to="/cookbook"
                icon={Book}
                title="Resources"
                isActive={location.pathname === '/cookbook'}
                navigate={navigate}
                setIsMenuOpen={setIsMenuOpen}
                setExpandedSection={setExpandedSection}
              />
              
              <NavItem
                to="/preferences"
                icon={User}
                title="My Account"
                isActive={location.pathname === '/preferences'}
                navigate={navigate}
                setIsMenuOpen={setIsMenuOpen}
                setExpandedSection={setExpandedSection}
              />

              <motion.button
                whileHover={{ scale: 1.02, backgroundColor: '#B39DDB' }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setIsInviteOpen(true)}
                style={{marginTop: 'auto'}}
                className="mt-auto w-full flex items-center justify-center gap-2 px-4 py-3 bg-[#B39DDB] border-[2px] border-[#B39DDB] rounded-md hover:text-white hover:bg-[#9575CD] transition-colors duration-200"
              >
                <UserPlus className="w-4 h-4" />
                <span className="text-sm font-medium">Invite Friend</span>
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <InviteFriendsDrawer 
        isOpen={isInviteOpen} 
        onClose={() => setIsInviteOpen(false)} 
      />
    </>
  );
};

export { Navbar };