import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
// import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FunconLogo from '../assets/funcon-logo-horizontal.svg';

const Input = ({ label, ...props }) => (
  <div className="relative">
    <input
      {...props}
      className="w-full bg-transparent border-b border-gray-600 focus:border-purple-500 text-white p-4 outline-none transition-all duration-300 placeholder-transparent"
      placeholder={label}
    />
    <label className="absolute left-0 -top-3.5 text-gray-400 text-sm transition-all duration-300">
      {label}
    </label>
  </div>
);

const Button = ({ text, ...props }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="w-full bg-purple-600 text-white py-4 rounded-full font-semibold text-lg shadow-lg hover:bg-purple-700 transition duration-300"
    {...props}
  >
    {text}
  </motion.button>
);

function Login() {
  const firstTimeAuthCheck = useRef(true)
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, authTokens } = useAuth();
  const navigate = useNavigate();
  

  useEffect(() => {
    if(firstTimeAuthCheck.current){
      if (authTokens) {
        const redirectTo = searchParams.get('redirect_to')
        navigate(redirectTo ?? '/');
        window.location.reload(); 
      }
      firstTimeAuthCheck.current = false
    }
  }, [authTokens, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await login(email, password);
      if (success) {
        const redirectTo = searchParams.get('redirect_to')
        toast.success('Logged in successfully!');
        navigate(redirectTo ?? '/');
        window.location.reload(); 
      } else {
        toast.error('Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('An error occurred during login. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#1a1a1a] text-white font-inter p-6">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <img src={FunconLogo} alt="Funcon Logo" className="mx-auto mb-12 w-40 h-auto" />
        <h1 className="text-4xl font-light text-center mb-12">Welcome Back</h1>
        <form onSubmit={handleSubmit} className="space-y-8">
          <Input 
            label="Email" 
            type="email"
            id="email"
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required
          />
          <Input 
            label="Password" 
            type="password"
            id="password"
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required
          />
          <p className="text-right mt-12 text-gray-400">
            {' '}
            <Link to="/reset-password-email" className="text-purple-400 hover:text-purple-300 transition duration-300">
            Forgot Password?
            </Link>
          </p>


          <Button text="Log In" type="submit" />
        </form>
        {/* Google Sign-in has been commented out
        <div className="mt-12 flex items-center justify-center">
          <div className="border-t border-gray-600 flex-grow"></div>
          <span className="mx-4 text-gray-400">or</span>
          <div className="border-t border-gray-600 flex-grow"></div>
        </div>
        <div className="mt-8 flex justify-center">
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={(error) => {
              console.error('Google Sign-In failed:', error);
              toast.error('Google Sign-In failed. Please try again.');
            }}
            theme="filled_black"
            size="large"
            shape="pill"
          />
        </div>
        */}
        <p className="text-center mt-12 text-gray-400">
          Don't have an account?{' '}
          <Link to={searchParams.get('redirect_to') ? `/signup?redirect_to=${searchParams.get('redirect_to')}`: '/signup'} className="text-purple-400 hover:text-purple-300 transition duration-300">
            Sign up
          </Link>
        </p>
      </motion.div>
      <ToastContainer position="bottom-center" theme="dark" />
    </div>
  );
}

export default Login;