import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  isToday,
  addDays,
  isBefore,
  isAfter,
  setHours,
  setMinutes,
  parse,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { ChevronLeft, ChevronRight, Calendar } from 'lucide-react';
import axios from 'axios';

const CustomCalendar = ({ onDateSelect, onComplete, mode, userTimezone }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const timezone = userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const today = new Date();
  const twoWeeksFromNow = addDays(today, 14);
  const fiveAndHalfWeeksFromNow = addDays(today, 38);

  useEffect(() => {
    if (selectedDate) {
      generateAvailableTimes(selectedDate);
    }
  }, [selectedDate, timezone]);

  const generateAvailableTimes = (date) => {
    const estTimes = [];
    for (let hour = 6; hour <= 23; hour++) {
      estTimes.push(setHours(date, hour));
    }

    const convertedTimes = estTimes.map(time => {
      const estTime = zonedTimeToUtc(time, 'America/New_York');
      const localTime = utcToZonedTime(estTime, timezone);
      return format(localTime, 'h:mm a');
    });

    setAvailableTimes(convertedTimes);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    onDateSelect(date);
  };

  const handleTimeClick = (time) => {
    setSelectedTime(time);
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      if (!selectedDate || !selectedTime) {
        throw new Error('Please select both a date and time.');
      }

      const [time, period] = selectedTime.split(' ');
      let [hours, minutes] = time.split(':');
      hours = parseInt(hours);
      minutes = parseInt(minutes);

      if (period === 'PM' && hours !== 12) {
        hours += 12;
      } else if (period === 'AM' && hours === 12) {
        hours = 0;
      }

      const combinedDateTime = new Date(selectedDate);
      combinedDateTime.setHours(hours, minutes, 0, 0);

      const utcDateTime = zonedTimeToUtc(combinedDateTime, timezone);

      if (mode === 'funcon') {
        onComplete({ scheduledDate: utcDateTime.toISOString() });
      } else if (mode === 'assistance') {
        const response = await axios.post('/api/schedule-assistance/', {
          datetime: utcDateTime.toISOString(),
        });
        onComplete(response.data);
      } else {
        console.log('Invalid mode.');
      }
    } catch (error) {
      console.error('Error scheduling event:', error);
      setError('Failed to schedule the event. Please try again.');
    } finally {
      setLoading(false);
      setShowConfirmation(false);
    }
  };

  const renderCalendarDays = () => {
    const startDay = startOfMonth(currentMonth);
    const endDay = endOfMonth(currentMonth);
    const days = eachDayOfInterval({ start: startDay, end: endDay });
    const firstDayIndex = startDay.getDay();

    return Array.from({ length: 42 }).map((_, index) => {
      const dayIndex = index - firstDayIndex;
      const date = days[dayIndex];

      if (!date || !isSameMonth(date, currentMonth)) {
        return <div key={index} className="h-10 w-10" />;
      }

      const isSelected = selectedDate && isSameDay(date, selectedDate);
      const isCurrentDay = isToday(date);
      const isPastDate = isBefore(date, twoWeeksFromNow);
      const isFutureDateBeyondRange = isAfter(date, fiveAndHalfWeeksFromNow);
      const isDisabled = isPastDate || isFutureDateBeyondRange;

      return (
        <motion.div
          key={index}
          className={`h-10 w-10 flex items-center justify-center rounded-full cursor-pointer relative ${
            isDisabled
              ? 'text-gray-600 cursor-not-allowed'
              : isSelected
              ? 'bg-gradient-to-br from-purple-500 to-indigo-600 text-white'
              : isCurrentDay
              ? 'text-purple-400 font-semibold'
              : 'text-gray-400 hover:text-white'
          }`}
          whileHover={isDisabled ? {} : { scale: 1.1 }}
          whileTap={isDisabled ? {} : { scale: 0.95 }}
          onClick={() => !isDisabled && handleDateSelect(date)}
        >
          {format(date, 'd', { timeZone: timezone })}
          {isCurrentDay && (
            <div className="absolute inset-0 border-2 border-purple-400 rounded-full" />
          )}
        </motion.div>
      );
    });
  };

  return (
    <div className="bg-gradient-to-br from-gray-900 to-black p-6 rounded-3xl shadow-lg relative">
      <div className="flex justify-between items-center mb-6">
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="cursor-pointer text-purple-400 hover:text-purple-300 transition-colors"
          onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
        >
          <ChevronLeft size={24} />
        </motion.div>
        <h2 className="text-xl font-light text-white">
          {format(currentMonth, 'MMMM yyyy', { timeZone: timezone })}
        </h2>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="cursor-pointer text-purple-400 hover:text-purple-300 transition-colors"
          onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
        >
          <ChevronRight size={24} />
        </motion.div>
      </div>

      <div className="grid grid-cols-7 gap-1 mb-4">
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
          <div key={`${day}-${index}`} className="text-center text-gray-500 font-medium text-sm">
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1 mb-6">{renderCalendarDays()}</div>

      {selectedDate && (
        <div className="mt-6">
          <h3 className="text-lg mb-4 text-white font-light flex items-center">
            <Calendar className="mr-2 text-purple-400" size={18} />
            Available Times ({timezone})
          </h3>
          <div className="grid grid-cols-3 sm:grid-cols-4 gap-3">
            {availableTimes.map((time, index) => (
              <motion.div
                key={index}
                className={`text-center cursor-pointer py-2 px-3 rounded-lg ${
                  selectedTime === time ? 'bg-purple-500 text-white' : 'text-gray-300 hover:text-purple-400'
                } transition-colors`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleTimeClick(time)}
              >
                {time}
              </motion.div>
            ))}
          </div>
        </div>
      )}

      <AnimatePresence>
        {showConfirmation && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-gradient-to-br from-gray-900 to-black p-8 rounded-3xl shadow-2xl max-w-md w-full"
            >
              <h2 className="text-2xl mb-4 text-purple-400 font-light">
                Confirm Your {mode === 'assistance' ? 'Assistance Meeting' : 'Funcon'}
              </h2>
              <p className="mb-6 text-gray-300">
                You're about to schedule for:
                <br />
                <span className="text-purple-300 text-xl mt-2 block">
                  {selectedDate &&
                    format(selectedDate, 'MMMM d, yyyy', { timeZone: timezone })}{' '}
                  at {selectedTime} ({timezone})
                </span>
              </p>
              <div className="flex justify-end space-x-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="px-4 py-2 bg-gray-700 text-gray-300 rounded-lg hover:bg-gray-600 transition-colors"
                  onClick={() => setShowConfirmation(false)}
                >
                  Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-500 transition-colors"
                  onClick={handleConfirm}
                >
                  Confirm
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CustomCalendar;