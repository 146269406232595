import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-gray-300 py-12">
      <div className="max-w-4xl mx-auto p-6 !bg-[#242424] shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold mb-6 text-purple-400">Funcon Privacy Policy</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="mb-4">
            At Funcon, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and safeguarding of your data when you use our services.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
          <p className="mb-4">We collect the following types of information:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Email addresses</li>
            <li>Funcon topics you're interested in</li>
            <li>Attendance information for Funcon events</li>
          </ul>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
          <p className="mb-4">We use your information for the following purposes:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>To send event invitations to friends when authorized by you</li>
            <li>To track your personal Funcon activities and interests</li>
            <li>To improve our services and user experience</li>
          </ul>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Data Sharing and Disclosure</h2>
          <p className="mb-4">
            We do not sell, rent, or trade your personal information to third parties. Your data is only shared when you explicitly authorize us to send invitations to your friends.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
          <p className="mb-4">
            We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
          <p className="mb-4">You have the right to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Access your personal data</li>
            <li>Correct inaccuracies in your information</li>
            <li>Request deletion of your data</li>
            <li>Opt-out of certain data collection and use</li>
          </ul>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Changes to This Policy</h2>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Contact Us</h2>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <p className="mb-2">Email: hello@funcon.com</p>
          <p className="mb-2">Address: 930 e Passyunk Ave, Philadelphia, PA 19147</p>
        </section>
        
        <footer className="text-sm text-gray-500">
          Last Updated: September 13, 2024
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;