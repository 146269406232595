import React from 'react';

function TextArea({ label, value, onChange, error = false }) {
  return (
    <label className="block mb-4">
      <span className="block text-textSecondary mb-1">{label}</span>
      <textarea
        value={value}
        onChange={onChange}
        className={`w-full p-3 rounded-md bg-surface border border-borderColor focus:border-primary focus:ring-2 focus:ring-primary focus:outline-none ${error ? 'border-error' : ''}`}
        rows="5"
      />
      {error && <span className="text-error text-sm mt-1">This field is required</span>}
    </label>
  );
}

export default TextArea;
