import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  HelpCircle,
  X,
  ChevronLeft,
  MessageCircle,
  BookOpen,
  ChevronRight,
} from 'lucide-react';
import CustomCalendar from '../components/CustomCalendar';

const FunconScheduler = ({ onComplete, funconData, timezone }) => {
  // const [showHelp, setShowHelp] = useState(false);
  const [showAssistanceCalendar, setShowAssistanceCalendar] = useState(false);

  const handleDateTimeSelect = (dateTime) => {
    // This function is no longer needed as the CustomCalendar handles the confirmation
    console.log('Date and time selected:', dateTime);
  };

  const helpSections = {
    main: {
      title: 'How can we help?',
      content: [
        {
          icon: MessageCircle,
          text: 'Chat with us 1:1',
          action: () => setShowAssistanceCalendar(true),
        },
        {
          icon: BookOpen,
          text: 'Read hosting tips',
          action: () => window.open('/cookbook', '_blank'),
        },
      ],
    },
  };

  // const HelpContent = () => (
  //   <motion.div
  //     initial={{ opacity: 0, scale: 0.95 }}
  //     animate={{ opacity: 1, scale: 1 }}
  //     exit={{ opacity: 0, scale: 0.95 }}
  //     transition={{ duration: 0.2 }}
  //     className="fixed inset-0 bg-black bg-opacity-90 backdrop-blur-sm flex items-center justify-center z-50"
  //   >
  //     {showAssistanceCalendar ? (
  //       <div className="w-full max-w-md">
  //         <span
  //           onClick={() => setShowAssistanceCalendar(false)}
  //           className="mb-4 text-purple-400 hover:text-purple-300 transition-colors duration-300 flex items-center cursor-pointer"
  //         >
  //           <ChevronLeft size={20} />
  //           <span className="ml-1">Back to help options</span>
  //         </span>
  //         <CustomCalendar
  //           onDateSelect={() => {}}
  //           onComplete={() => {
  //             setShowAssistanceCalendar(false);
  //             // setShowHelp(false);
  //           }}
  //           mode="assistance"
  //           userTimezone={timezone}
  //         />
  //       </div>
  //     ) : (
  //       <motion.div
  //         className="bg-gray-900 rounded-3xl p-6 w-full max-w-sm relative overflow-hidden"
  //         layoutId="help-container"
  //       >
  //         <motion.div
  //           className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors cursor-pointer"
  //           whileHover={{ scale: 1.1 }}
  //           whileTap={{ scale: 0.9 }}
  //           onClick={() => setShowHelp(false)}
  //         >
  //           <X size={24} />
  //         </motion.div>

  //         <h3 className="text-2xl font-light mb-6 text-white">
  //           {helpSections.main.title}
  //         </h3>
  //         <div className="space-y-4">
  //           {helpSections.main.content.map((item, index) => (
  //             <motion.div
  //               key={index}
  //               className="flex items-center space-x-4 text-gray-300 hover:text-white transition-colors cursor-pointer"
  //               onClick={item.action}
  //               whileHover={{ x: 5 }}
  //             >
  //               {item.icon && <item.icon size={24} />}
  //               <span className="text-lg">{item.text}</span>
  //               <ChevronRight size={20} className="ml-auto" />
  //             </motion.div>
  //           ))}
  //         </div>
  //       </motion.div>
  //     )}
  //   </motion.div>
  // );

  return (
    <div className="space-y-6 relative">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-3xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-purple-300 via-purple-500 to-indigo-400">
          Schedule Your Funcon
        </h2>
        {/* <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
          className="text-purple-400 hover:text-purple-300 transition-colors duration-300 cursor-pointer"
          onClick={() => setShowHelp(true)}
        >
          <HelpCircle size={24} />
        </motion.div> */}
      </div>

      <CustomCalendar
        onDateSelect={handleDateTimeSelect}
        onComplete={onComplete}
        mode="funcon"
        userTimezone={timezone}
      />

      {/* <AnimatePresence>{showHelp && <HelpContent />}</AnimatePresence> */}
    </div>
  );
};

export default FunconScheduler;