import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, CheckIcon } from 'lucide-react';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import FunconScheduler from './FunconScheduler';
import TopicGenerator from './TopicGenerator';
import InviteFriendsComponent from '../components/Invites';
import TimezoneSelector from '../components/TimezoneSelector.js';

const HostFuncon = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [funconData, setFunconData] = useState({});
  const [timezone, setTimezone] = useState('');
  const [funconCreated, setFunconCreated] = useState(false);

  useEffect(() => {
    // Get user's timezone on component mount
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(userTimezone);
  }, []);

  const steps = [
    { component: FunconScheduler, title: 'Schedule' },
    { component: TopicGenerator, title: 'Topics' },
    { component: InviteFriendsComponent, title: 'Invite' },
  ];

  const funconCreatedRef = useRef(false);

  const createFuncon = async () => {
    if (funconCreatedRef.current) return;

    try {
      if (!funconData.topic) {
        throw new Error('A topic is required to create a Funcon.');
      }

      const scheduledDate = new Date(funconData.scheduledDate);
      const response = await axios.post('/api/create-funcon/', {
        datetime: scheduledDate.toISOString(),
        timezone: timezone,
        topic: funconData.topic,
      });
      const funconId = response.data.funconId;
      setFunconData((prevData) => ({ ...prevData, funconId }));
      funconCreatedRef.current = true;
      console.log('Funcon created:', funconId);
      return funconId;  // Return the funconId
    } catch (error) {
      console.error('Error creating Funcon:', error);
      throw error;  // Re-throw the error to be caught in handleComplete
    }
  };

  const handleComplete = async (stepData) => {
    console.log('handleComplete called with stepData:', stepData);
    setFunconData((prevData) => {
      const newData = { ...prevData, ...stepData };
      console.log('Updated funconData:', newData);
      return newData;
    });

    // Create Funcon immediately if inviteOption is 'auto' and not already created
    if (stepData.inviteOption === 'auto' && !funconCreatedRef.current) {
      try {
        const funconId = await createFuncon();
        setFunconData((prevData) => ({ ...prevData, funconId }));
      } catch (error) {
        console.error('Error creating Funcon:', error);
      }
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      let funconId;
      // If Funcon wasn't created earlier, create it now
      if (!funconCreatedRef.current) {
        try {
          funconId = await createFuncon();
          setFunconData((prevData) => ({ ...prevData, funconId }));
        } catch (error) {
          console.error('Error creating Funcon:', error);
        }
      }

      // Continue with any post-creation logic
      const updatedFunconData = { ...funconData, ...stepData };
      console.log('Final updatedFunconData:', updatedFunconData);

      const inviteOption =
        updatedFunconData.inviteOption || stepData.inviteOption;
      console.log('Invite option:', inviteOption);

      if (inviteOption === 'auto') {
        // Auto-invites are handled server-side upon Funcon creation
        console.log('Auto-invite selected.');
        // Optionally, you can add any client-side logic here if needed
      } else if (
        updatedFunconData.friends &&
        updatedFunconData.friends.length > 0
      ) {
        try {
          await Promise.all(
            updatedFunconData.friends.map((friend) =>
              axios.post('/api/invite', {
                ...friend,
                funconId: funconData.funconId ?? funconId,
              })
            )
          );
          console.log('Manual invites sent.');
        } catch (error) {
          console.error('Error sending manual invites:', error);
        }
      }

      console.log('Funcon planning completed!', updatedFunconData);
      // Navigate to success page or update UI
    }
  };

  const StepIndicator = ({ currentStep, steps }) => (
    <div className="flex justify-center items-center space-x-4 sm:space-x-6 md:space-x-8 mb-12">
      {steps.map((_, index) => (
        <motion.div
          key={index}
          className={`w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center ${
            index < currentStep
              ? 'bg-purple-500'
              : index === currentStep
              ? 'bg-gradient-to-br from-purple-500 to-indigo-600'
              : 'bg-gray-700'
          }`}
          initial={false}
          animate={{
            scale: index === currentStep ? 1.1 : 1,
            boxShadow:
              index === currentStep
                ? '0 0 20px rgba(168, 85, 247, 0.5)'
                : 'none',
          }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          {index < currentStep ? (
            <CheckIcon className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6 text-white" />
          ) : index === currentStep ? (
            <motion.div
              className="w-2 h-2 sm:w-3 sm:h-3 md:w-4 md:h-4 bg-white rounded-full"
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ duration: 1.5, repeat: Infinity }}
            />
          ) : (
            <div className="w-2 h-2 sm:w-3 sm:h-3 md:w-4 md:h-4 bg-gray-400 rounded-full" />
          )}
        </motion.div>
      ))}
    </div>
  );

  return (
    <div className="bg-[#1a1a1a] min-h-screen text-white font-inter flex flex-col">
      <NavigationBar />
      <main className="flex-grow flex items-center justify-center p-4 sm:p-6 md:p-8">
        <div className="w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl">
          <motion.div
            className="mb-8 sm:mb-12 text-center"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-light mb-6">
              Create Your Funcon
            </h1>
            <StepIndicator currentStep={currentStep} steps={steps} />
          </motion.div>
          <div className="mb-6 flex flex-col items-center justify-center w-full max-w-xs mx-auto">
            <TimezoneSelector
              selectedTimezone={timezone}
              onTimezoneChange={setTimezone}
            />
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentStep}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-gradient-to-br from-gray-900 to-black rounded-3xl p-6 sm:p-8 shadow-2xl"
            >
              {React.createElement(steps[currentStep].component, {
                onComplete: handleComplete,
                funconData: funconData,
                timezone: timezone,
              })}
            </motion.div>
          </AnimatePresence>
          <div className="flex justify-between mt-8">
            {currentStep > 0 && !funconData.funconId && (
              <motion.button
                whileHover={{ x: -5 }}
                className="text-purple-400 hover:text-purple-300 transition-colors cursor-pointer flex items-center text-sm sm:text-base"
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                <ChevronLeft size={16} className="mr-1" />
                Back
              </motion.button>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HostFuncon;
