import React from 'react';
import { useLocation } from 'react-router-dom';
import FunconLogo from '../assets/funcon-logo-horizontal.svg'; 

// YARD: remove this on frontend
function EmailSent() {
  const location = useLocation();
  const email = location.state?.email || '';

  return (
    <div className="min-h-screen flex items-center justify-center bg-background text-textPrimary font-sans p-6">
      <div className="bg-surface p-10 rounded-lg shadow-lg w-full max-w-lg text-center">
      <img src={FunconLogo} alt="Funcon Logo" className="mx-auto mb-8 w-20 h-auto" />
        <h1 className="text-3xl font-bold mb-4">Verify Your Email</h1>
        <p className="mb-4">
          We've sent a verification email to <strong>{email}</strong>. 
          Please check your inbox and click the verification link to complete your registration.
        </p>
        <p>
          If you don't see the email, please check your spam folder.
        </p>
      </div>
    </div>
  );
}

export default EmailSent;