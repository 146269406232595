import axios from 'axios';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const useAxios = () => {
  const { authTokens, setAuthTokens, logout, refreshToken } = useContext(AuthContext);

  const axiosInstance = axios.create({
  });

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        if (authTokens?.access) {
          config.headers['Authorization'] = `Bearer ${authTokens.access}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const newTokens = await refreshToken();
            if (newTokens) {
              originalRequest.headers['Authorization'] = `Bearer ${newTokens.access}`;
              return axiosInstance(originalRequest);
            }
          } catch (refreshError) {
            logout();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [authTokens, setAuthTokens, logout, refreshToken]);

  return axiosInstance;
};

export default useAxios;