import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const BlogPost = ({ title, content, date, author, category }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-[#1a1a1a] text-gray-100 min-h-screen font-sans">
      {/* Navigation */}
      <nav className="bg-[#1a1a1a] shadow-md fixed top-0 left-0 right-0 z-50">
        <div className="max-w-4xl mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/cookbook" className="flex items-center space-x-2 text-purple-300 hover:text-purple-200 transition-colors">
            <ArrowLeft size={20} />
            <span className="font-semibold">Back to Cookbook</span>
          </Link>
        </div>
      </nav>

      {/* Main Content */}
      <main className="max-w-4xl mx-auto px-4 py-24">
        <motion.article
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="space-y-6"
        >
          <h1 className="text-4xl font-bold text-purple-300 mb-2">{title}</h1>
          <div className="flex items-center space-x-4 text-sm text-gray-400 mb-8">
            {date && <span>{date}</span>}
            {author && (
              <>
                <span>•</span>
                <span>{author}</span>
              </>
            )}
            {category && (
              <>
                <span>•</span>
                <span className="bg-purple-800 text-purple-200 px-2 py-1 rounded">{category}</span>
              </>
            )}
          </div>
          {content && content.map((item, index) => {
            switch (item.type) {
              case 'paragraph':
                return <p key={index} className="text-gray-300 leading-relaxed">{item.text}</p>;
              case 'subtitle':
                return <h2 key={index} className="text-2xl font-semibold text-purple-200 mt-8 mb-4">{item.text}</h2>;
              case 'list':
                return (
                  <ul key={index} className="list-disc pl-6 space-y-2 text-gray-300">
                    {item.items.map((listItem, listIndex) => (
                      <li key={listIndex}>{listItem}</li>
                    ))}
                  </ul>
                );
                case 'button':
                  return (
                    <Link 
                      key={index}
                      to={item.url} 
                      className="inline-block mt-6 px-6 py-3 bg-purple-600 text-white rounded-full font-medium hover:bg-purple-700 transition-colors duration-300"
                    >
                      {item.text}
                    </Link>
                  );
              default:
                return null;
            }
          })}
        </motion.article>
      </main>

      {/* Footer */}
      <footer className="bg-[#242424] py-8 mt-16">
        <div className="max-w-4xl mx-auto px-4 text-center text-gray-400">
          <p>&copy; 2024 Funcon. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default BlogPost;