import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { ChevronLeft, Mail, Sparkles, Send, Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import axios from 'axios';
import Button from './Button';

const InviteFriendsComponent = ({ onComplete, funconData }) => {
  const [step, setStep] = useState(0);
  const [inviteOption, setInviteOption] = useState(funconData?.inviteOption || null);
  const [friends, setFriends] = useState(funconData?.invites || [{ email: '', message: '' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const hasCompletedRef = useRef(false);

  const steps = [
    { title: "Choose Your Path", component: InitialView },
    { title: "Craft Your Constellation", component: DetailsView },
    { title: "Launch Your Funcon", component: SuccessView }
  ];

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(prevStep => prevStep - 1);
    }
  };

  const handleComplete = (data) => {
    if (hasCompletedRef.current) return;
    hasCompletedRef.current = true;

    setIsLoading(true);
    setTimeout(() => {
      console.log('InviteFriendsComponent handleComplete called with data:', data);
      setIsLoading(false);
      setStep(steps.length - 1); // Set to the last step (SuccessView)
    }, 1500);
  };

  const handleSubmitData = useCallback((data) => {
    onComplete({ ...data, inviteOption });
  },[onComplete, inviteOption ])

  const CurrentComponent = steps[step].component;

  return (
    <motion.div
      className="w-full max-w-md mx-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center mb-8">
        {step > 0 && step < steps.length - 1 && !funconData.funconId && (
          <ChevronLeft
            size={32}
            onClick={handleBack}
            className="mr-4 text-purple-400 hover:text-purple-300 cursor-pointer flex-shrink-0"
          />
        )}
        <div className="flex-grow">
          <h1 className="text-3xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-purple-300 via-purple-500 to-indigo-400">
            Perspectives
          </h1>
        </div>
      </div>
      <AnimatePresence mode="wait">
        <motion.div
          key={step}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <CurrentComponent
            inviteOption={inviteOption}
            setInviteOption={setInviteOption}
            friends={friends}
            setFriends={setFriends}
            handleNext={handleNext}
            handleBack={handleBack}
            handleComplete={handleComplete}
            handleSubmitData={handleSubmitData}
            isLoading={isLoading}
            error={error}
            setError={setError}
          />
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

const InitialView = ({ setInviteOption, handleNext, handleSubmitData }) => (
  <motion.div className="space-y-8">
    <div className="space-y-4">
      <Option
        icon={<Sparkles />}
        title="Let Funcon handle it"
        description="We will invite all of the perspectives."
        onClick={() => {
          setInviteOption('auto');
          handleNext();
          handleSubmitData();
        }}
      />
      <Option
        icon={<Mail />}
        title="Invite Friends"
        description="Invite up to 2 new friends to join your Funcon"
        onClick={() => {
          setInviteOption('manual');
          handleNext();
        }}
      />
    </div>
  </motion.div>
);

const Option = ({ icon, title, description, onClick }) => (
  <motion.button
    onClick={onClick}
    className="w-full bg-gray-800 bg-opacity-50 p-6 rounded-xl flex items-center space-x-4 hover:bg-opacity-70 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
  >
    <div className="bg-purple-500 bg-opacity-10 p-3 rounded-full">
      {React.cloneElement(icon, { size: 24, className: 'text-purple-300' })}
    </div>
    <div className="flex-grow text-left">
      <h3 className="text-lg font-semibold text-white">{title}</h3>
      <p className="text-sm text-gray-300">{description}</p>
    </div>
  </motion.button>
);

const DetailsView = ({ inviteOption, friends, setFriends, handleComplete, error, setError, handleSubmitData }) => {
  if (!inviteOption) {
    return null; // Or return a loading state/error message
  }

  return (
    <motion.div className="space-y-8">
      {inviteOption === 'auto' ? (
        <AutoInvite onAnimationComplete={handleComplete} inviteOption={inviteOption} />
      ) : (
        <FriendInvite
          friends={friends}
          setFriends={setFriends}
          handleComplete={handleComplete}
          handleSubmitData={handleSubmitData}
          error={error}
          setError={setError}
          inviteOption={inviteOption}
        />
      )}
    </motion.div>
  );
};

const AutoInvite = ({ onAnimationComplete, inviteOption }) => {
  const [currentStage, setCurrentStage] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const iconControls = useAnimation();
  const shadowControls = useAnimation();
  const stageTimer = useRef(null);
  const stages = [
    { text: "Exploring your topic", color: "#FF6B6B" },
    { text: "Discovering unique viewpoints", color: "#4ECDC4" },
    { text: "Weaving ideas together", color: "#45B7D1" },
    { text: "Crafting perspectives", color: "#F7B801" },
    { text: "Preparing your Funcon", color: "#8A2BE2" }
  ];

  useEffect(() => {
    const advanceStage = () => {
      setCurrentStage(prevStage => {
        if (prevStage < stages.length - 1) {
          return prevStage + 1;
        } else {
          setIsComplete(true);
          return prevStage;
        }
      });
    };
    stageTimer.current = setInterval(advanceStage, 3000);
    return () => clearInterval(stageTimer.current);
  }, [stages.length]);

  useEffect(() => {
    if (isComplete) {
      clearInterval(stageTimer.current);
      onAnimationComplete();
    }
  }, [isComplete, onAnimationComplete]);

  useEffect(() => {
    shadowControls.start({
      boxShadow: `0 0 30px 10px ${stages[currentStage].color}`,
      transition: { duration: 0.5 }
    });
  }, [currentStage, stages, shadowControls]);

  useEffect(() => {
    const pulseAnimation = {
      scale: [1, 1.05, 1],
      transition: {
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse"
      }
    };

    if (isComplete) {
      iconControls.stop();
      shadowControls.stop();
      iconControls.set({ scale: 1 });
      shadowControls.set({ scale: 1 });
    } else {
      iconControls.start({
        ...pulseAnimation,
        rotate: 360,
        transition: {
          ...pulseAnimation.transition,
          rotate: { duration: 20, ease: "linear", repeat: Infinity }
        }
      });
      shadowControls.start(pulseAnimation);
    }
  }, [isComplete, iconControls, shadowControls]);

  const currentColor = stages[currentStage].color;

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="relative w-48 h-48 mb-8">
        <motion.div
          className="absolute inset-0 rounded-full"
          animate={shadowControls}
          initial={{ boxShadow: `0 0 30px 10px ${stages[0].color}` }}
        />
        <motion.div
          className="absolute inset-0 rounded-full flex items-center justify-center"
          animate={iconControls}
        >
          <Sparkles className="text-white" size={64} style={{ color: currentColor }} />
        </motion.div>
      </div>
      <AnimatePresence mode="wait">
        <motion.div 
          key={currentStage}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <h2 className="text-3xl font-light mb-4" style={{ color: currentColor }}>
            {stages[currentStage].text}
          </h2>
          <p className="text-lg text-gray-400">
            Please wait while we prepare your Funcon...
          </p>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const FriendInvite = ({ friends, setFriends, handleComplete, handleSubmitData, error, setError }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFriendChange = (index, field, value) => {
    const newFriends = [...friends];
    newFriends[index] = { ...newFriends[index], [field]: value };
    setFriends(newFriends);
  };

  const addFriend = () => {
    if (friends.length < 2) {
      setFriends([...friends, { email: '', message: '' }]);
    }
  };

  const sendInvite = async (friend) => {
    try {
      const response = await axios.post('/api/invite', {
        email: friend.email,
        message: friend.message
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : new Error('Network error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = friends.every(friend => emailRegex.test(friend.email));
    if (!isValid) {
      setError('Please enter valid email addresses.');
      setIsLoading(false);
      return;
    }

    try {
      for (const friend of friends) {
        await sendInvite(friend);
      }
      handleSubmitData({ inviteOption: 'manual', friends });
      handleComplete({ inviteOption: 'manual', friends });
    } catch (error) {
      setError(error.message || 'Failed to send invites. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {friends.map((friend, index) => (
        <motion.div
          key={index}
          className="space-y-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: index * 0.2 }}
        >
          <Input
            type="email"
            value={friend.email}
            onChange={(e) => handleFriendChange(index, 'email', e.target.value)}
            placeholder={`Friend ${index + 1}'s email`}
            required
          />
          <Input
            as="textarea"
            value={friend.message}
            onChange={(e) => handleFriendChange(index, 'message', e.target.value)}
            placeholder={`Message for Friend ${index + 1} (optional)`}
            rows="3"
          />
        </motion.div>
      ))}
      <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4">
        {friends.length < 2 && (
          <motion.button
            type="button"
            onClick={addFriend}
            className="group flex items-center justify-center w-full sm:w-auto px-6 py-3 border border-purple-500 rounded-full text-purple-500 hover:text-white hover:bg-purple-500 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Plus size={20} className="mr-2 group-hover:rotate-90 transition-transform duration-300" />
            <span className="font-medium">Add Friend</span>
          </motion.button>
        )}
        <motion.button
          type="submit"
          disabled={isLoading}
          className={`w-full sm:w-auto px-8 py-3 bg-gradient-to-r from-purple-500 to-indigo-600 rounded-full text-white font-medium shadow-lg hover:shadow-xl transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 ${
            isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:from-purple-600 hover:to-indigo-700'
          }`}
          whileHover={isLoading ? {} : { scale: 1.02 }}
          whileTap={isLoading ? {} : { scale: 0.98 }}
        >
          {isLoading ? (
            <motion.div
              className="w-6 h-6 border-t-2 border-white rounded-full animate-spin"
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            />
          ) : (
            'Send Invitations'
          )}
        </motion.button>
      </div>
    </form>
  );
};

const SuccessView = ({ inviteOption }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      className="text-center space-y-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <ConfettiExplosion />
      <motion.div
        className="w-24 h-24 mx-auto relative"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: 'spring', stiffness: 260, damping: 20 }}
      >
        <div className="absolute inset-0 rounded-full bg-purple-500 opacity-20 blur-md"></div>
        <div className="absolute inset-1 rounded-full bg-[#1a1a1a] flex items-center justify-center">
          <Send className="text-purple-300" size={32} />
        </div>
      </motion.div>
      <h2 className="text-2xl font-light text-white">
        {inviteOption === 'auto' ? 'Funcon Created' : 'Invites Sent'}
      </h2>
      <p className="text-lg text-gray-300">
        {inviteOption === 'auto'
          ? 'Your Funcon is set up and ready to go!'
          : 'Your Funcon is ready to go!'}
      </p>
      <div className="flex justify-center">
        <motion.button
          onClick={() => navigate('/')}
          className="px-6 py-3 bg-purple-600 text-white rounded-full font-medium hover:bg-purple-700 transition-colors duration-300 min-w-[150px] flex items-center justify-center"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Back to Home
        </motion.button>
      </div>
    </motion.div>
  );
};

const ConfettiExplosion = () => {
  const [explode, setExplode] = useState(false);

  useEffect(() => {
    setTimeout(() => setExplode(true), 500);
  }, []);

  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  return <Confetti active={explode} config={config} />;
};

const Input = ({ as: Component = 'input', ...props }) => (
  <Component
    {...props}
    className="w-full p-4 bg-gray-800 bg-opacity-50 rounded-xl border-2 border-purple-700 focus:border-purple-500 focus:ring-2 focus:ring-purple-500 text-white placeholder-gray-400 transition-all duration-300 resize-none"
  />
);

export default InviteFriendsComponent;
