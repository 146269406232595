import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import { createPortal } from 'react-dom';

const Tooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);
  const targetRef = useRef(null);
  useEffect(() => {
    const updatePosition = () => {
      if (targetRef.current && tooltipRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        setPosition({
          x: rect.left + rect.width / 2,
          y: rect.top - 12,
        });
      }
    };
    if (isVisible) {
      updatePosition();
      window.addEventListener("scroll", updatePosition);
      window.addEventListener("resize", updatePosition);
    }
    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, [isVisible]);
  return (
    <div className="relative inline-block">
      <div
        ref={targetRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onFocus={() => setIsVisible(true)}
        onBlur={() => setIsVisible(false)}
      >
        {children}
      </div>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            ref={tooltipRef}
            initial={{ opacity: 0, y: 8 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 8 }}
            transition={{ duration: 0.2, ease: [0.4, 0, 0.2, 1] }}
            className="absolute z-50 px-4 py-2.5 text-sm font-medium text-white bg-[#2A2A2A] rounded-lg shadow-xl backdrop-blur-sm -translate-x-1/2 -translate-y-full whitespace-nowrap border border-[#B39DDC]/20"
            style={{
              left: position.x,
              top: position.y,
            }}
          >
            {content}
            <div className="absolute w-2.5 h-2.5 bg-[#2A2A2A] border-r border-b border-[#B39DDC]/20 transform rotate-45 -bottom-1.25 left-1/2 -translate-x-1/2" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
const CalendarOption = ({ icon, label, onClick, href, description }) => {
  const Component = href ? "a" : "button";
  const props = href
    ? { href, target: "_blank", rel: "noopener noreferrer" }
    : { onClick };

  return (
    <Tooltip content={description}>
      <Component
        {...props}
        className={`flex items-center w-full px-6 py-4 text-sm transition-all duration-300 group relative overflow-hidden focus:outline-none focus-visible:ring-2 focus-visible:ring-[#B39DDC]/50 ${
          href 
            ? 'text-gray-200 hover:text-white hover:bg-[#B39DDC]/5' 
            : 'text-gray-200 hover:text-[#B39DDC] bg-transparent'
        }`}
        role={href ? "menuitem" : "button"}
      >
        {/* Icon */}
        <motion.span 
          className={`w-5 h-5 mr-4 relative z-10 ${href ? 'text-[#B39DDC]' : 'text-gray-400 group-hover:text-[#B39DDC]'}`}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          {icon}
        </motion.span>

        {/* Label */}
        <span className="font-medium relative z-10 transition-transform duration-300 group-hover:translate-x-1">
          {label}
        </span>

        {/* Arrow for links */}
        {href && (
          <motion.div
            className="ml-auto text-[#B39DDC] opacity-0 group-hover:opacity-100 relative z-10"
            initial={{ x: -8, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <motion.svg
              className="w-4 h-4"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </motion.svg>
          </motion.div>
        )}
      </Component>
    </Tooltip>
  );
};
const AddToCalendarDropdown = ({ funcon, children }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isGeneratingFile, setIsGeneratingFile] = useState(false);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const eventTitle = "FUNCON(details in description)";
  const eventDescription = `<b>Host:</b> ${funcon.host.email}<br/>
<b>Topic:</b> ${funcon.topic.description}<br/>
<a href="https://www.funcon.community/signup?invite=func0nl1v325">Join Funcon</a> 🫶  <br/>
<a href="https://www.funcon.community/host">Host a Funcon</a> 📚 <br/>
<a href="https://www.funcon.community/preferences">Get more Funcon invites</a> 📩 <br/>
Join your Funcon: ${funcon.topic.description}`;
  const eventDescriptionForIcs = `Host: ${funcon.host.email} \\nTopic: ${funcon.topic.description} \\nJoin Funcon - https://www.funcon.community/signup?invite=func0nl1v325 \\nHost a Funcon - https://www.funcon.community/host \\nGet more Funcon invites - https://www.funcon.community/preferences \\nJoin your Funcon: ${funcon.topic.description}`;
  const eventLocation = funcon.zoom_link;
  const eventStartDate = funcon.scheduled_datetime;
  const date = new Date(funcon.scheduled_datetime);
  date.setHours(date.getHours() + 1);
  const eventEndDate = date.toISOString();
  const eventDetails = {
    title: eventTitle,
    description: eventDescription,
    location: eventLocation,
    startDateTime: eventStartDate,
    endDateTime: eventEndDate,
    descriptionForIcs: eventDescriptionForIcs,
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setError(null);
  };
  const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    eventDetails.title
  )}&dates=${eventDetails.startDateTime.replace(
    /[-:]/g,
    ""
  )}%2F${eventDetails.endDateTime.replace(
    /[-:]/g,
    ""
  )}&details=${encodeURIComponent(
    eventDetails.description
  )}&location=${encodeURIComponent(eventDetails.location)}`;
  const outlookCalendarLink = `https://outlook.office.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(
    eventDetails.title
  )}&startdt=${eventDetails.startDateTime}&enddt=${
    eventDetails.endDateTime
  }&body=${encodeURIComponent(
    eventDetails.description
  )}&location=${encodeURIComponent(eventDetails.location)}`;
  const generateICSFile = async () => {
    try {
      setIsGeneratingFile(true);
      setError(null);
      const icsData = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Funcon//Calendar Event//EN
CALSCALE:GREGORIAN
METHOD:PUBLISH
BEGIN:VEVENT
SUMMARY:${eventDetails.title}
DESCRIPTION:${eventDetails.descriptionForIcs}
LOCATION:${eventDetails.location}
DTSTART:${eventDetails.startDateTime.replace(/[-:]/g, "")}
DTEND:${eventDetails.endDateTime.replace(/[-:]/g, "")}
DTSTAMP:${new Date().toISOString().replace(/[-:]/g, "").split(".")[0]}Z
STATUS:CONFIRMED
SEQUENCE:0
END:VEVENT
END:VCALENDAR`;
      const blob = new Blob([icsData], { type: "text/calendar;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${eventDetails.title.replace(/\s+/g, "_")}.ics`;
      await new Promise((resolve) => setTimeout(resolve, 800));
      link.click();
      URL.revokeObjectURL(url);
      setDropdownOpen(false);
    } catch (error) {
      console.error("Error generating ICS file:", error);
      setError("Failed to generate calendar file. Please try again.");
    } finally {
      setIsGeneratingFile(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };
    const handleKeyboard = (event) => {
      if (event.key === "Escape") {
        setDropdownOpen(false);
      }
      if (dropdownOpen && event.key === "Tab") {
        const focusableElements = dropdownRef.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
        if (event.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          event.preventDefault();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          event.preventDefault();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyboard);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyboard);
    };
  }, [dropdownOpen]);
  return (
    <div className="relative inline-block text-left font-inter w-full">
      <motion.div
        ref={buttonRef}
        onClick={toggleDropdown}
        onKeyDown={(e) => e.key === "Enter" && toggleDropdown()}
        role="button"
        tabIndex={0}
        aria-haspopup="true"
        aria-expanded={dropdownOpen}
        className="cursor-pointer outline-none focus-visible:ring-2 focus-visible:ring-[#B39DDC]/50 rounded-lg w-full"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        {children}
      </motion.div>

      {/* Portal the dropdown menu to body */}
      {dropdownOpen && createPortal(
        <div className="fixed inset-0 z-50">
          {/* Backdrop */}
          <div 
            className="fixed inset-0 bg-black/20 backdrop-blur-sm"
            onClick={() => setDropdownOpen(false)}
          />
          
          {/* Dropdown menu */}
          <div className="fixed left-0 right-0 bottom-0 sm:static">
            <motion.div
              ref={dropdownRef}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{
                duration: 0.2,
                ease: [0.4, 0, 0.2, 1],
              }}
              className="relative mx-4 mb-4 sm:mx-0 sm:mb-0 sm:absolute sm:right-0 sm:mt-3 w-full sm:w-80 origin-top-right 
                bg-[#1A1A1A] rounded-xl border border-[#B39DDC]/20 shadow-lg shadow-black/40 
                backdrop-blur-sm overflow-hidden"
              style={{
                boxShadow: '0 4px 24px -4px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(179, 157, 220, 0.1)',
              }}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="calendar-button"
            >
              <div className="py-1.5 divide-y divide-[#B39DDC]/10">
                <CalendarOption
                  href={googleCalendarLink}
                  icon={
                    <svg viewBox="0 0 24 24" fill="currentColor">
                      <path d="M19.5 3h-15A1.5 1.5 0 003 4.5v15A1.5 1.5 0 004.5 21h15a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0019.5 3zm-15 1.5h15v3h-15v-3zm0 4.5h3v3h-3v-3zm4.5 0h3v3h-3v-3zm4.5 0h3v3h-3v-3zm3 10.5h-12v-6h12v6z" />
                    </svg>
                  }
                  label="Add to Google Calendar"
                  description="Open event in Google Calendar"
                />
                <CalendarOption
                  href={outlookCalendarLink}
                  icon={
                    <svg viewBox="0 0 24 24" fill="currentColor">
                      <path d="M21.179 4.885l-7.679-4.43a4.167 4.167 0 00-4.149.044L2.822 4.885A2.5 2.5 0 002 6.943v10.114a2.5 2.5 0 001.179 2.058l7.679 4.43a4.167 4.167 0 004.149-.044l6.529-4.386a2.5 2.5 0 001.179-2.058V6.943a2.5 2.5 0 00-1.179-2.058zM12 11l8.5-2.5v9L12 20l-8.5-2.5v-9L12 11z" />
                    </svg>
                  }
                  label="Add to Outlook"
                  description="Open event in Outlook Calendar"
                />
                <CalendarOption
                  onClick={generateICSFile}
                  icon={
                    isGeneratingFile ? (
                      <motion.svg
                        animate={{ rotate: 360 }}
                        transition={{
                          duration: 1,
                          repeat: Infinity,
                          ease: "linear",
                        }}
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48l2.83 2.83M2 12h4m12 0h4M4.93 19.07l2.83-2.83m8.48-8.48l2.83-2.83" />
                      </motion.svg>
                    ) : (
                      <svg viewBox="0 0 24 24" fill="currentColor">
                        <path d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zM5 19v-2h14v2H5zm14-4H5V5h14v10z" />
                        <path d="M7 7h2v2H7zM11 7h2v2h-2zM15 7h2v2h-2zM7 11h2v2H7zM11 11h2v2h-2zM15 11h2v2h-2z" />
                      </svg>
                    )
                  }
                  label={isGeneratingFile ? "Generating..." : "Download for Apple Calendar"}
                  description="Download .ics file for Apple Calendar or other apps"
                />
              </div>
            </motion.div>
          </div>
        </div>,
        document.body
      )}

      {/* Loading overlay */}
      {isGeneratingFile && createPortal(
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center bg-[#1A1A1A]/90 backdrop-blur-md z-[100]"
        >
          <div className="absolute inset-0 bg-[#1A1A1A]/60" />
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            className="relative flex flex-col items-center space-y-4"
          >
            <div className="relative w-12 h-12">
              {/* Outer ring */}
              <motion.div
                className="absolute inset-0"
                style={{
                  border: '2px solid rgba(179, 157, 220, 0.1)',
                  borderRadius: '50%',
                  borderTopColor: '#B39DDC',
                  borderRightColor: 'rgba(179, 157, 220, 0.5)',
                }}
                animate={{ rotate: 360 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
              {/* Middle ring */}
              <motion.div
                className="absolute inset-1"
                style={{
                  border: '2px solid rgba(179, 157, 220, 0.05)',
                  borderRadius: '50%',
                  borderTopColor: 'rgba(179, 157, 220, 0.7)',
                  borderLeftColor: 'rgba(179, 157, 220, 0.3)',
                }}
                animate={{ rotate: -360 }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
              {/* Inner ring */}
              <motion.div
                className="absolute inset-2"
                style={{
                  border: '2px solid rgba(179, 157, 220, 0.03)',
                  borderRadius: '50%',
                  borderBottomColor: 'rgba(179, 157, 220, 0.6)',
                }}
                animate={{ rotate: 180 }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "linear"
                }}
              />
              {/* Center dot */}
              <motion.div
                className="absolute inset-[35%] bg-[#B39DDC] rounded-full"
                animate={{ 
                  scale: [1, 1.2, 1],
                  opacity: [0.5, 1, 0.5]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
            </div>
            <motion.span 
              className="text-sm font-medium text-gray-200"
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              Generating calendar file...
            </motion.span>
          </motion.div>
        </motion.div>,
        document.body
      )}

      {/* Error toast */}
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed bottom-6 left-1/2 transform -translate-x-1/2 px-4 py-3 bg-red-900/95 text-white rounded-lg shadow-xl backdrop-blur-sm border border-red-700/30 flex items-center space-x-3 z-50"
          >
            <svg
              className="w-5 h-5 text-red-400 flex-shrink-0"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="text-sm font-medium">{error}</span>
            <button
              onClick={() => setError(null)}
              className="p-1 text-red-400 hover:text-red-300 transition-colors duration-200"
            >
              <svg
                className="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
// PropTypes for type checking
AddToCalendarDropdown.propTypes = {
  funcon: PropTypes.shape({
    host: PropTypes.shape({
      email: PropTypes.string.isRequired,
    }).isRequired,
    topic: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
    zoom_link: PropTypes.string.isRequired,
    scheduled_datetime: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default AddToCalendarDropdown;
