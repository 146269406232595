import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-gray-300 py-12">
      <div className="max-w-4xl mx-auto p-6 !bg-[#242424] shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold mb-6 text-purple-400">Funcon Terms of Service</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">1. Acceptance of Terms</h2>
          <p className="mb-4">
            By accessing or using Funcon's services, you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not access the service.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">2. Description of Service</h2>
          <p className="mb-4">
            Funcon provides a platform for users to organize and manage social events ("Funcons") with their friends. Our service includes event creation, invitation management, and attendance tracking.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">3. User Accounts</h2>
          <p className="mb-4">
            You must create an account to use certain features of our service. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">4. User Conduct</h2>
          <p className="mb-4">You agree not to use the service to:</p>
          <ul className="list-disc pl-6 mb-4 text-gray-400">
            <li>Violate any laws or regulations</li>
            <li>Infringe on the rights of others</li>
            <li>Send spam or unsolicited messages</li>
            <li>Distribute malware or engage in malicious activities</li>
            <li>Impersonate others or provide false information</li>
          </ul>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">5. Intellectual Property</h2>
          <p className="mb-4">
            The service and its original content, features, and functionality are owned by Funcon and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">6. Termination</h2>
          <p className="mb-4">
            We may terminate or suspend your account and bar access to the service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever, including without limitation if you breach the Terms.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">7. Limitation of Liability</h2>
          <p className="mb-4">
            In no event shall Funcon, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">8. Changes to Terms</h2>
          <p className="mb-4">
            We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">9. Governing Law</h2>
          <p className="mb-4">
            These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
          </p>
        </section>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-purple-300">10. Contact Us</h2>
          <p className="mb-4">
            If you have any questions about these Terms, please contact us at:
          </p>
          <p className="mb-2">Email: <a href="mailto:hello@funcon.com" className="text-purple-400 hover:text-purple-300">hello@funcon.com</a></p>
          <p className="mb-2">Address: 930 e Passyunk Ave, Philadelphia, PA 19147</p>
        </section>
        
        <footer className="text-sm text-gray-500">
          Last Updated: September 13, 2024
        </footer>
      </div>
    </div>
  );
};

export default TermsOfService;