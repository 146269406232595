import React from 'react';

function Button({ text, onClick, className = '', disabled = false }) {
  return (
    <button
      className={`p-3 rounded-md bg-primary text-black font-semibold hover:bg-primary-dark disabled:bg-gray-600 ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

export default Button;