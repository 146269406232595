import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Home from './pages/Home';
import JoinFuncon from './pages/JoinFuncon';
import HostFuncon from './pages/HostFuncon';
import Feedback from './pages/Feedback';
import Login from './pages/Login';
import Signup from './pages/Signup';
import EmailSent from './pages/EmailSent';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider, AuthContext } from './contexts/AuthContext'; // Ensure import is correct
import './index.css';
import EmailVerification from './pages/EmailVerification';
import LandingPage from './pages/LandingPage';
import PreferencesPage from './pages/PreferencesPage';
import Host from './pages/HostFuncon';
import TopicGenerator from './pages/TopicGenerator';
import Cookbook from './pages/Cookbook';
import Invites from './components/Invites';
import FirstInvite from './cookbook/new-members/FirstInvite'; // Import your FirstInvite component
import BlogPost from './pages/BlogPostPage';
import { blogPosts } from './content/blog-metadata';
import EmailConfirmed from './pages/EmailConfirmed';
import PrivacyPolicy from './pages/Privacy';
import TermsOfService from './pages/TermsOfService';
import { Analytics } from "@vercel/analytics/react"
import FunconScheduler from './pages/Admin/FunconScheduler';
import AdminPage from './pages/AdminPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/EmailResetPasswords";
import Upcoming from './pages/Upcoming';
import DeclinedFuncons from './pages/DeclinedFuncons';
import ViewFuncon from './pages/ViewFuncon';


function App() {
  const { user, loading } = useContext(AuthContext);
  const queryClient = new QueryClient();

  if (loading) {
    return <div>Loading...</div>;  // Show loading until auth state is resolved
  }
  return (
    <GoogleOAuthProvider clientId="164186423790-1el1dliure7vpqdvc1bel81tltdl9lfr.apps.googleusercontent.com">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/email-sent" element={<EmailSent />} /> 
            <Route path="/verify-email/:key" element={<EmailVerification />} />
            <Route path="/confirm-email/:uid/:token" element={<EmailConfirmed />} />
            <Route path="/reset-password-email" element={<ForgotPassword />} />
            <Route path="/reset-password/:uid/:token" Component={ResetPassword} />
            <Route path="/" element={user ? <Home /> : <LandingPage />} />
            <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/join" element={<ProtectedRoute><JoinFuncon /></ProtectedRoute>} />
            <Route path="/host" element={<ProtectedRoute><Host /></ProtectedRoute>} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/feedback" element={<ProtectedRoute><Feedback /></ProtectedRoute>} />
            <Route path="/preferences" element={<ProtectedRoute><PreferencesPage /></ProtectedRoute>} />
            <Route 
              path="/admin" 
              element={
                <ProtectedRoute requireSuperuser={true}>
                  <QueryClientProvider client={queryClient}>
                    <FunconScheduler />
                  </QueryClientProvider>
                </ProtectedRoute>
              }
            />
            <Route path="/cookbook" element={<Cookbook />} />
            <Route path="/cookbook/new-members/first-invite" element={<FirstInvite />} />
            <Route 
              path="/blog/:id" 
              element={<BlogPost />} 
              loader={({ params }) => {
                const post = blogPosts.find(p => p.id === params.id);
                if (!post) throw new Error('Post not found');
                return post;
              }}
            />

            <Route path="/upcoming" element={<ProtectedRoute><Upcoming /></ProtectedRoute>} />
            <Route path="/upcoming/declined" element={<ProtectedRoute><DeclinedFuncons /></ProtectedRoute>} />
            <Route path="/view-funcon/:id" element={<ProtectedRoute><ViewFuncon /></ProtectedRoute>} />
          </Routes>
          <Analytics />
        </Router>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;