import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Users,
  Calendar,
  CheckCircle,
  X,
  Plus,
  ArrowLeft,
  Settings,
  Search,
  UserPlus,
  UserMinus,
  HelpCircle,
  Crown,
  Share2,
  RefreshCw,
} from 'lucide-react';
import { Link, useSearchParams } from 'react-router-dom';
import confetti from 'canvas-confetti';
import { Check } from 'lucide-react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery, useQueryClient } from 'react-query';
import { Checkbox, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Select from 'react-select';

const AdminFunconScheduler = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const openFunconRef = useRef(true)
  const [activeTab, setActiveTab] = useState('created');
  const [selectedFuncon, setSelectedFuncon] = useState(null);
  const [sendingReminders, setSendingReminders] = useState(false);
  const queryClient = useQueryClient();

  const { data: funcons, isLoading, isError, error, refetch } = useQuery(
    'funcons',
    fetchFuncons,
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    }
  );

  useEffect(() => {
    const funconId = searchParams.get('funcon-id')
    if(funconId && openFunconRef.current && funcons?.length){
      const selectedFuncon = funcons.find((funcon) => funcon.id.toString() === funconId)
      if(selectedFuncon){
        setSelectedFuncon(selectedFuncon)
      }
      openFunconRef.current = false
    }
  },[searchParams, funcons])


  useEffect(() => {
    const intervalId = setInterval(() => {
      if (activeTab === 'scheduled') {
        updateCalendarResponses();
      }
    }, 5 * 60 * 1000); // Update every 5 minutes

    return () => clearInterval(intervalId);
  }, [activeTab]);

  const handleSendReminders = async () => {
    setSendingReminders(true);
    toast.info('Sending reminders...');
    try {
      const response = await axios.post('/api/admin/funcons/send_reminders/');
      toast.success(`Reminders sent: ${response.data.reminders_sent}`);
    } catch (error) {
      console.error('Failed to send reminders:', error);
      toast.error('Failed to send reminders. Please try again.');
    } finally {
      setSendingReminders(false);
    }
  };

  const updateCalendarResponses = async () => {
    const scheduledFuncons = funcons?.filter((funcon) => funcon.status === 'scheduled') || [];
    const funconIds = scheduledFuncons.map((funcon) => funcon.id);
    if (funconIds.length === 0) return;
  
    try {
      const response = await axios.post('/api/admin/funcons/update_calendar_responses/', {
        funcon_ids: funconIds,
      });
      const updatedResponses = response.data;
  
      queryClient.setQueryData('funcons', (oldData) =>
        oldData.map((funcon) => {
          if (updatedResponses[funcon.id]) {
            return {
              ...funcon,
              calendar_responses: updatedResponses[funcon.id],
            };
          }
          return funcon;
        })
      );
    } catch (error) {
      console.error('Failed to update calendar responses:', error);
    }
  };

  async function fetchFuncons() {
    const response = await axios.get('/api/admin/funcons/');
    return Array.isArray(response.data)
      ? response.data.sort(
          (a, b) => new Date(a.scheduled_datetime) - new Date(b.scheduled_datetime)
        )
      : [];
  }

  const filteredFuncons = useMemo(() => {
    return Array.isArray(funcons)
      ? funcons.filter((funcon) => funcon.status === activeTab)
      : [];
  }, [funcons, activeTab]);

  const handleRefresh = () => {
    refetch();
    if (activeTab === 'scheduled') {
      updateCalendarResponses();
    }
  };

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage message={error.message} />;

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 font-inter">
      <NavBar />
      <main className="p-4 sm:p-8 max-w-7xl mx-auto">
        <header className="mb-6 sm:mb-12 flex justify-between items-center">
          <div>
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-purple-400 mb-2">
              Funcon Lab
            </h1>
            <p className="text-base sm:text-lg md:text-xl text-gray-400">
              Craft and manage extraordinary Funcons
            </p>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleRefresh}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            >
              <RefreshCw size={20} />
            </button>
            <button
              onClick={handleSendReminders}
              disabled={sendingReminders}
              className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
            >
              {sendingReminders ? 'Sending...' : 'Send Reminders'}
            </button>
          </div>
        </header>
        <TabSelector activeTab={activeTab} setActiveTab={setActiveTab} funcons={funcons} />
        <AnimatePresence mode="wait">
          {selectedFuncon ? (
            <FunconDetails
              key="details"
              funcon={selectedFuncon}
              onClose={() => {
                setSelectedFuncon(null);
                refetch();
                setSearchParams({})
              }}
              onUpdate={() => {
                refetch();
              }}
            />
          ) : (
            <FunconGrid
              key="grid"
              funcons={filteredFuncons}
              onSelectFuncon={setSelectedFuncon}
              activeTab={activeTab}
            />
          )}
        </AnimatePresence>
      </main>
      <ToastContainer />
    </div>
  );
};

const NavBar = () => (
  <nav className="bg-gray-800 shadow-md">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16">
        <div className="flex">
          <Link to="/" className="flex-shrink-0 flex items-center">
            <ArrowLeft className="h-6 w-6 text-gray-400 mr-2" />
            <span className="text-lg sm:text-xl font-semibold text-gray-100">Home</span>
          </Link>
        </div>
        <div className="flex items-center">
          <span className="text-sm sm:text-base text-gray-100 font-medium mr-4">
            Funcon Admin
          </span>
          <Settings className="h-6 w-6 text-gray-400 cursor-pointer" />
        </div>
      </div>
    </div>
  </nav>
);

const TabSelector = ({ activeTab, setActiveTab, funcons }) => {
  const tabs = [
    { id: 'created', label: 'Created', icon: Plus },
    { id: 'scheduled', label: 'Scheduled', icon: Calendar },
    { id: 'completed', label: 'Completed', icon: CheckCircle },
  ];

  const getCount = (status) => {
    return funcons.filter(funcon => funcon.status === status).length;
  };

  return (
    <div className="flex flex-wrap justify-center sm:justify-start space-x-2 space-y-2 sm:space-y-0 mb-6 sm:mb-8">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setActiveTab(tab.id)}
          className={`flex items-center px-4 sm:px-6 py-2 sm:py-3 rounded-full text-sm font-medium transition-colors ${
            activeTab === tab.id
              ? 'bg-purple-600 text-white shadow-lg'
              : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
          }`}
        >
          <tab.icon className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
          {tab.label}
          <span className="ml-2 bg-gray-700 text-gray-300 px-2 py-1 rounded-full text-xs">
            {getCount(tab.id)}
          </span>
        </button>
      ))}
    </div>
  );
};

const FunconGrid = ({ funcons, onSelectFuncon, activeTab }) => {
  if (funcons.length === 0) {
    let message = '';
    switch (activeTab) {
      case 'created':
        message = 'All caught up! Go get some more hosts!';
        break;
      case 'scheduled':
        message = 'We need more in the pipeline!';
        break;
      case 'completed':
        message = "Here's the road to 10K";
        break;
      default:
        message = 'No Funcons available';
    }

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex justify-center items-center h-64"
      >
        <p className="text-2xl text-gray-400 font-light">{message}</p>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8"
    >
      {funcons.map((funcon) => (
        <FunconCard key={funcon.id} funcon={funcon} onSelect={() => onSelectFuncon(funcon)} />
      ))}
    </motion.div>
  );
};

const FunconCard = ({ funcon, onSelect }) => {
  const calendarResponses = funcon.calendar_responses || {};

  const acceptedCount = Object.values(calendarResponses).filter((r) => r === 'accepted').length;
  const declinedCount = Object.values(calendarResponses).filter((r) => r === 'declined').length;
  const noResponseCount = Object.values(calendarResponses).filter(
    (r) => r === 'needsAction'
  ).length;

  const highlightClass =
    acceptedCount >= 5
      ? 'border-green-500 bg-green-900 bg-opacity-20'
      : acceptedCount === 4
      ? 'border-yellow-500 bg-yellow-900 bg-opacity-20'
      : acceptedCount < 4 && funcon.status === 'scheduled'
      ? 'border-red-500 bg-red-900 bg-opacity-20'
      : 'border-gray-700';

  return (
    <motion.div
      layoutId={`funcon-${funcon.id}`}
      onClick={onSelect}
      className={`bg-gray-800 rounded-2xl p-4 sm:p-6 shadow-lg hover:shadow-xl transition-shadow cursor-pointer border ${highlightClass}`}
      whileHover={{ y: -5 }}
    >
      <div className="flex justify-between items-start mb-3 sm:mb-4">
        <p className="text-xs sm:text-sm text-gray-400">Host: {funcon.host?.email}</p>
        <span
          className={`text-xs px-2 py-1 rounded-full ${
            funcon.status === 'created'
              ? 'bg-yellow-900 text-yellow-200'
              : funcon.status === 'scheduled'
              ? 'bg-green-900 text-green-200'
              : 'bg-blue-900 text-blue-200'
          }`}
        >
          {funcon.status}
        </span>
      </div>
      <p className="text-base sm:text-lg font-semibold text-gray-100 mb-3 sm:mb-4">
        {new Date(funcon.scheduled_datetime).toLocaleString([], {
          dateStyle: 'medium',
          timeStyle: 'short',
        })}
      </p>
      {funcon.status === 'scheduled' && (
        <>
          <p className="text-xs sm:text-sm text-green-400">Accepted: {acceptedCount}</p>
          <p className="text-xs sm:text-sm text-red-400">Declined: {declinedCount}</p>
          <p className="text-xs sm:text-sm text-yellow-400">
            No Response: {noResponseCount}
          </p>
          <p className="text-xs sm:text-sm text-gray-400 mt-2">
            Last Invite Sent:{' '}
            {new Date(funcon.last_invite_sent).toLocaleString([], {
              dateStyle: 'medium',
              timeStyle: 'short',
            })}
          </p>
        </>
      )}
    </motion.div>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case 'accepted':
      return '#34D399';
    case 'declined':
      return '#F87171';
    case 'maybe':
      return '#FBBF24';
    default:
      return '#9CA3AF';
  }
};

const getContrastColor = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? '#000000' : '#FFFFFF';
};

const customSelectStyles = {
  control: (base) => ({
    ...base,
    background: '#2A2A2A',
    borderColor: '#4A4A4A',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#6A6A6A',
    },
  }),
  menu: (base) => ({
    ...base,
    background: '#2A2A2A',
    border: '1px solid #4A4A4A',
  }),
  option: (base, state) => ({
    ...base,
    background: state.isFocused ? '#3A3A3A' : '#2A2A2A',
    color: '#E0E0E0',
    '&:active': {
      background: '#4A4A4A',
    },
  }),
  input: (base) => ({
    ...base,
    color: '#E0E0E0',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#E0E0E0',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#4A4A4A',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: '#E0E0E0',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: '#E0E0E0',
    ':hover': {
      backgroundColor: '#6A6A6A',
      color: '#FFFFFF',
    },
  }),
};

const formatOptionLabel = ({ label, status, invitation_statistics }, {context}) => {
  const invitedPercentile = invitation_statistics?.times_invited ? invitation_statistics.times_accepted/invitation_statistics.times_invited : NaN
  let inviteCountClassname = 'text-gray-400'
  if(!invitation_statistics?.times_invited){
    inviteCountClassname = 'text-gray-400'
  }else if(invitedPercentile < 0.33){
    inviteCountClassname = 'text-red-400'
  }else if(invitedPercentile > 0.66){
    inviteCountClassname = 'text-green-400'
  }else{
    inviteCountClassname = 'text-yellow-400'
  }
  return <div style={{ display: 'flex', alignItems: 'center' }}>
    <div
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: getStatusColor(status),
        marginRight: '8px',
      }}
    />
    <span style={{flexGrow: 1}}>{label}</span>
    {context === 'menu' && <span className={inviteCountClassname}>{invitation_statistics?.times_invited ?? 0}</span>}
  </div>
};

const FunconDetails = ({ funcon, onClose, onUpdate }) => {
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [schedulingSuccess, setSchedulingSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updatingParticipants, setUpdatingParticipants] = useState(false);
  const theme = useTheme();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchAllParticipants();
  }, []);

  useEffect(() => {
    const selectedIds = new Set(funcon.participants.map((p) => p.id));
    const participantOptions = participants.filter((participant) => selectedIds.has(participant.id) || (participant.user?.is_active ?? true)).map((participant) => {
      const email = participant.user ? participant.user.email : participant.email;
      const responseStatus = funcon.calendar_responses ? funcon.calendar_responses[email] : 'needsAction';
      return {
        value: participant.id,
        label: `${participant.user?.first_name ?? ''} ${participant.user?.last_name ?? ''}`.trim() || participant.email,
        status: responseStatus,
        invitation_statistics : participant.invitation_statistics
      };
    }).sort((prev, next) => {
      if(!next.invitation_statistics){
        return 1
      }
      if(!prev.invitation_statistics){
        return -1
      }
      return prev.invitation_statistics.times_invited - next.invitation_statistics.times_invited
    });
    setOptions(participantOptions);
  }, [participants, funcon.calendar_responses]);

  const fetchAllParticipants = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/admin/participants/');
      const allParticipants = response.data.filter(participant => !funcon.unlinked_participant_ids.includes(participant.id));
      setParticipants(allParticipants);

      const selectedIds = new Set(funcon.participants.map((p) => p.id));
      setSelectedParticipants(
        allParticipants.filter((p) => selectedIds.has(p.id))
      );
    } catch (error) {
      console.error('Failed to fetch participants:', error);
      toast.error('Failed to fetch participants. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleParticipantToggle = (participant) => {
    if (selectedParticipants.some((p) => p.id === participant.id)) {
      setSelectedParticipants(selectedParticipants.filter((p) => p.id !== participant.id));
    } else {
      setSelectedParticipants([...selectedParticipants, participant]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredParticipants = useMemo(() => {
    return participants.filter((participant) => {
      const name = participant.user
        ? `${participant.user.first_name} ${participant.user.last_name}`.toLowerCase()
        : '';
      const email = participant.email.toLowerCase();
      const search = searchTerm.toLowerCase();
      return name.includes(search) || email.includes(search);
    });
  }, [participants, searchTerm]);

  const handleSubmit = async () => {
    setUpdatingParticipants(true);
    try {
      const participantIds = selectedParticipants.map((p) => p.id);
      if (funcon.status === 'created') {
        await axios.post(`/api/admin/funcons/${funcon.id}/schedule/`, {
          participant_ids: participantIds,
        });
      } else {
        await axios.post(`/api/admin/funcons/${funcon.id}/update_participants/`, {
          participant_ids: participantIds
        });
      }
      setSchedulingSuccess(true);
    } catch (error) {
      console.error('Failed to update participants:', error);
      toast.error('Failed to update participants. Please try again.');
    } finally {
      setUpdatingParticipants(false);
    }
  };

  const renderParticipant = (participant) => {
    const isSelected = selectedParticipants.some((p) => p.id === participant.id);
    const name = participant.user
      ? `${participant.user.first_name} ${participant.user.last_name}`.trim()
      : participant.email;

    return (
      <div
        key={participant.id}
        className="flex items-center p-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition-colors"
      >
        <Checkbox
          checked={isSelected}
          onChange={() => handleParticipantToggle(participant)}
          sx={{
            color: theme.palette.primary.main,
            '&.Mui-checked': {
              color: theme.palette.primary.main,
            },
          }}
        />
        <span className="ml-3 text-sm sm:text-base text-gray-300 font-medium">{name}</span>
      </div>
    );
  };

  const participantCounts = useMemo(() => {
    const counts = { accepted: 0, declined: 0, maybe: 0, needsAction: 0 };
    if (funcon.calendar_responses) {
      Object.values(funcon.calendar_responses).forEach((response) => {
        if (counts[response] !== undefined) {
          counts[response]++;
        }
      });
    }
    return counts;
  }, [funcon.calendar_responses]);

  const sortedParticipants = useMemo(() => {
    if (!funcon.participants) return [];
    return funcon.participants.map((participant) => {
      const email = participant.user?.email || participant.email;
      const inviteStatus = funcon.calendar_responses
        ? funcon.calendar_responses[email] || 'needsAction'
        : 'needsAction';
      return { ...participant, inviteStatus };
    });
  }, [funcon.participants, funcon.calendar_responses]);

  const renderParticipantName = (participant) => {
    if (participant.user) {
      const firstName = participant.user.first_name.trim();
      const lastName = participant.user.last_name.trim();
      const fullName = `${firstName} ${lastName}`.trim();
      return fullName || participant.email;
    } else {
      return participant.email;
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'accepted':
        return <CheckCircle size={16} className="text-green-400" />;
      case 'declined':
        return <X size={16} className="text-red-400" />;
      case 'maybe':
        return <CheckCircle size={16} className="text-yellow-400" />;
      default:
        return <X size={16} className="text-gray-400" />;
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'accepted':
        return 'Accepted';
      case 'declined':
        return 'Declined';
      case 'maybe':
        return 'Maybe';
      default:
        return 'No Response';
    }
  };

  const handleRemoveParticipant = (participantId) => {
    setSelectedParticipants(selectedParticipants.filter(p => p.id !== participantId));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm flex items-start justify-center z-50 p-4 overflow-y-auto"
    >
      <motion.div
        layoutId={`funcon-${funcon.id}`}
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.9 }}
        className="bg-gray-800 rounded-3xl p-4 sm:p-8 w-full max-w-2xl mx-auto shadow-2xl my-4 sm:my-8 max-h-[90vh] overflow-y-auto"
      >
        {schedulingSuccess ? (
          <SuccessAnimation
            onClose={() => {
              onUpdate();
              onClose();
            }}
            funcon={funcon}
          />
        ) : (
          <>
            <div className="flex justify-between items-center mb-6 sm:mb-8">
              <h2 className="text-2xl sm:text-3xl font-bold text-purple-400">Funcon Details</h2>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-white transition-colors p-2"
                aria-label="Close"
              >
                <X size={24} />
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 mb-6 sm:mb-8">
              <div className="bg-gray-700 p-3 sm:p-4 rounded-xl">
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-purple-300">Host</h3>
                <p className="text-sm sm:text-base text-gray-300">{funcon.host?.email}</p>
              </div>
              <div className="bg-gray-700 p-3 sm:p-4 rounded-xl">
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-purple-300">
                  Date & Time
                </h3>
                <p className="text-sm sm:text-base text-gray-300">
                  {new Date(funcon.scheduled_datetime).toLocaleString([], {
                    dateStyle: 'full',
                    timeStyle: 'short',
                  })}
                </p>
              </div>
              <div className="bg-gray-700 p-3 sm:p-4 rounded-xl md:col-span-2">
                <h3 className="text-lg sm:text-xl font-semibold mb-2 text-purple-300">Topic</h3>
                <p className="text-sm sm:text-base text-gray-300">
                  {funcon.topic?.description}
                </p>
              </div>
            </div>

            <div className="mb-6">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
                <h3 className="text-xl sm:text-2xl font-semibold text-purple-300 mb-2 sm:mb-0">
                  Participants
                </h3>
                <div className="flex flex-wrap gap-2 text-xs sm:text-sm">
                    <span className="text-green-400">
                      {participantCounts.accepted || 0} Accepted
                    </span>
                    <span className="text-red-400">
                      {participantCounts.declined || 0} Declined
                    </span>
                    <span className="text-yellow-400">
                      {participantCounts.maybe || 0} Maybe
                    </span>
                    <span className="text-gray-400">
                      {participantCounts.needsAction || 0} No Response
                    </span>
                  </div>
              </div>
              <div className="mt-4">
                <Select
                  isMulti
                  options={options}
                  value={options.filter((option) =>
                    selectedParticipants.some((p) => p.id === option.value)
                  )}
                  onChange={(selectedOptions) => {
                    const selectedIds = selectedOptions.map((option) => option.value);
                    setSelectedParticipants(
                      participants.filter((p) => selectedIds.includes(p.id))
                    );
                  }}
                  isSearchable
                  placeholder="Select participants..."
                  styles={customSelectStyles}
                  formatOptionLabel={formatOptionLabel}
                />
              </div>
              <button
                onClick={handleSubmit}
                disabled={updatingParticipants}
                className="mt-4 bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 w-full sm:w-auto"
              >
                {funcon.status === 'created' ? 'Schedule Funcon' : 'Update Participants'}
              </button>
            </div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};

const SuccessAnimation = ({ onClose, funcon }) => {
  useEffect(() => {
    // Trigger enhanced confetti explosion
    confetti({
      particleCount: 200,
      spread: 80,
      origin: { y: 0.6 },
      scalar: 1.2,
      colors: ['#ff6f61', '#ffcc00', '#2ecc71', '#3498db', '#9b59b6'],
    });

    // Close after delay
    const timer = setTimeout(() => {
      onClose();
    }, 1750);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <motion.div
      className="bg-gradient-to-br from-purple-900 to-indigo-900 rounded-3xl p-8 w-full max-w-2xl text-center relative overflow-hidden shadow-2xl"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, ease: 'easeOut' }}
    >
      <motion.div
        className="text-6xl mb-6"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        🎉
      </motion.div>
      <motion.h2
        className="text-4xl font-bold text-white mb-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        Funcon Scheduled!
      </motion.h2>
      <motion.p
        className="text-xl text-gray-300 mb-6"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
      >
        You've successfully created a new Funcon experience.
      </motion.p>
      <motion.div
        className="w-20 h-20 bg-green-500 rounded-full mx-auto flex items-center justify-center shadow-lg"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.5, duration: 0.5, type: 'spring' }}
      >
        <CheckCircle size={40} className="text-white" />
      </motion.div>
      <motion.div
        className="mt-6 text-lg text-gray-300"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.5 }}
      >
        One step closer to your goal!
      </motion.div>
    </motion.div>
  );
};

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
  </div>
);

const ErrorMessage = ({ message }) => (
  <div className="flex justify-center items-center h-screen">
    <div className="bg-red-600 text-white p-4 rounded-lg max-w-md text-center">
      <p className="font-bold mb-2">Error</p>
      <p>{message}</p>
    </div>
  </div>
);

const FunconScheduler = () => {
  return <AdminFunconScheduler />;
};

export default FunconScheduler;